import React, { useState ,useEffect} from "react";
import {motion} from 'framer-motion'
import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { MdClose, MdDone,MdEdit } from 'react-icons/md'
import Papa from 'papaparse'
import { createModel } from "../../API/models";
import { createEvaluationChat, createEvaluationData } from "../../API/evaluation_data";

export default function AddModelModal({closeModal,evaluation_id,comparison_count }) {
    const [stage, setStage] = useState("initial");
    const [generating,setGenerating]=useState(false)
    const [modelId,setModelId]=useState([])

    const [CSVCols,setCSVCols]=useState([])

    const [modelName,setModelName]=useState(null)

    const [convLevels,setConvLevels]=useState(2)
    const [question1Field,setQuestion1Field]=useState(null)
    const [question2Field,setQuestion2Field]=useState(null)
    const [question3Field,setQuestion3Field]=useState(null)
    const [question4Field,setQuestion4Field]=useState(null)
    const [question5Field,setQuestion5Field]=useState(null)
    const [question6Field,setQuestion6Field]=useState(null)
    const [question7Field,setQuestion7Field]=useState(null)
    const [question8Field,setQuestion8Field]=useState(null)
    const [question9Field,setQuestion9Field]=useState(null)
    const [question10Field,setQuestion10Field]=useState(null)
    const [answer1Field,setAnswer1Field]=useState(null)
    const [answer2Field,setAnswer2Field]=useState(null)
    const [answer3Field,setAnswer3Field]=useState(null)
    const [answer4Field,setAnswer4Field]=useState(null)
    const [answer5Field,setAnswer5Field]=useState(null)
    const [answer6Field,setAnswer6Field]=useState(null)
    const [answer7Field,setAnswer7Field]=useState(null)
    const [answer8Field,setAnswer8Field]=useState(null)
    const [answer9Field,setAnswer9Field]=useState(null)
    const [answer10Field,setAnswer10Field]=useState(null)

    // State to store parsed data
    const [modelData,setModelData]=useState([])

    const fileUploadHandler=(e)=>{
        Papa.parse(e.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
              setModelData(results.data);
              setCSVCols(results.meta.fields);
            },
          });
    }
    const generateData = async () => {
        setGenerating(true);
        for (const a of modelData) {
            let obj = {
                "question1": a[question1Field],
                "question2": a[question2Field],
                "question3": a[question3Field],
                "question4": a[question4Field],
                "question5": a[question5Field],
                "question6": a[question6Field],
                "question7": a[question7Field],
                "question8": a[question8Field],
                "question9": a[question9Field],
                "question10": a[question10Field],
                "answer1": a[answer1Field],
                "answer2": a[answer2Field],
                "answer3": a[answer3Field],
                "answer4": a[answer4Field],
                "answer5": a[answer5Field],
                "answer6": a[answer6Field],
                "answer7": a[answer7Field],
                "answer8": a[answer8Field],
                "answer9": a[answer9Field],
                "answer10": a[answer10Field]
            };
    
            // First we create a data object
            const res = await createEvaluationData({
                "evaluation_model_id": modelId,
                "first_question": obj.question1,
                "evaluation_id":evaluation_id
            });
    
            if (obj.question1) {
                console.log("QUESTION 1 Exists");
                await createEvaluationChat({
                    "evaluation_data_id": res.id,
                    "question": obj.question1,
                    "answer": obj.answer1,
                    "level": 1,
                });
            }
    
            if (obj.question2) {
                console.log("QUESTION 2 Exists");
                await createEvaluationChat({
                    "evaluation_data_id": res.id,
                    "question": obj.question2,
                    "answer": obj.answer2,
                    "level": 2,
                });
            }
    
            if (obj.question3) {
                console.log("QUESTION 3 Exists");
                await createEvaluationChat({
                    "evaluation_data_id": res.id,
                    "question": obj.question3,
                    "answer": obj.answer3,
                    "level": 3,
                });
            }
    
            if (obj.question4) {
                console.log("QUESTION 4 Exists");
                await createEvaluationChat({
                    "evaluation_data_id": res.id,
                    "question": obj.question4,
                    "answer": obj.answer4,
                    "level": 4,
                });
            }
    
            if (obj.question5) {
                console.log("QUESTION 5 Exists");
                await createEvaluationChat({
                    "evaluation_data_id": res.id,
                    "question": obj.question5,
                    "answer": obj.answer5,
                    "level": 5,
                });
            }
    
            if (obj.question6) {
                await createEvaluationChat({
                    "evaluation_data_id": res.id,
                    "question": obj.question6,
                    "answer": obj.answer6,
                    "level": 6,
                });
            }
    
            if (obj.question7) {
                await createEvaluationChat({
                    "evaluation_data_id": res.id,
                    "question": obj.question7,
                    "answer": obj.answer7,
                    "level": 7,
                });
            }
    
            if (obj.question8) {
                await createEvaluationChat({
                    "evaluation_data_id": res.id,
                    "question": obj.question8,
                    "answer": obj.answer8,
                    "level": 8,
                });
            }
    
            if (obj.question9) {
                await createEvaluationChat({
                    "evaluation_data_id": res.id,
                    "question": obj.question9,
                    "answer": obj.answer9,
                    "level": 9,
                });
            }
    
            if (obj.question10) {
                await createEvaluationChat({
                    "evaluation_data_id": res.id,
                    "question": obj.question10,
                    "answer": obj.answer10,
                    "level": 10,
                });
            }
        }
        setGenerating(false)
        // All operations are complete, now close the modal
        closeModal();
    };
 
    const modelCreate=async()=>{
        const res=await createModel({evaluation_id,"name":modelName,"comparison_count":comparison_count})
        setStage("file_upload")
        setModelId(res.id)
    }
    return (
      <div className="z-10 fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
        <motion.div initial={{translateY:-window.innerHeight/2}} animate={{translateY:0}} className="w-4/5 relative bg-white m-4 rounded-md shadow-lg">
        <motion.button onClick={closeModal} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='absolute top-4 right-4 p-4 text-lg border border-gray-200 bg-white rounded hover:bg-gray-100 '>
            <MdClose/>
        </motion.button>
        <div className='flex flex-col items-start text-center w-full bg-white rounded-lg h-4/5 shadow-lg p-8 overflow-y-auto'>
            <h2 className='text-3xl font-bold bg-purple-900 px-4 py-2 rounded-md text-white text-left'>Add Model</h2>
            {stage==="initial" && <div className='w-full border-2 border-gray-200 rounded-xl shadow flex flex-col items-start p-6 mt-4'>
                <h2 className='text-2xl font-bold text-gray-900 mb-4'>Model Details</h2>
                <div className='w-full mb'>
                    <label className="block text-lg text-left text-gray-700 font-semibold">
                        Model Title
                    </label>
                    <input
                        type="text"
                        value={modelName}
                        onChange={(e)=>setModelName(e.target.value)}
                        placeholder="Model Title"
                        className="w-full text-md p-3 border border-gray-300 rounded-lg"
                    />
                </div>
                {/** Conversation Levels */}
                <div className='w-full flex items-center justify-between my-2'>
                    <div className='text-left'>
                        <p className='text-lg font-semibold'>Conversation Levels</p>
                        <p className='text-md text-gray-600'>How many level fieldname in CSV s of conversation (max 10)</p>
                    </div>
                    <label class="inline-flex items-center cursor-pointer ">
                        <button onClick={()=>{
                            if(convLevels>0){
                                setConvLevels(convLevels-1)
                            }
                        }} className='h-12 w-12 bg-white flex items-center justify-center border-t border-b border-l border-gray-300'><BiMinus/></button>
                        <p className='h-12 w-12 border border-gray-300 text-center flex items-center justify-center bg-white'>{convLevels}</p>
                        <button onClick={()=>{
                            if(convLevels<10){
                                setConvLevels(convLevels+1)
                            }
                        }} className='h-12 w-12 bg-white flex items-center justify-center border-t border-b border-r border-gray-300'><BiPlus/></button>
                    </label>
                </div>
                <div className='w-full flex items-end justify-end'>
                    <button onClick={modelCreate} className='bg-purple-900 text-white font-semibold px-4 py-2'>Next</button>
                </div>
            </div>}
            {/**DOCUMENT UPLOAD */}
            {stage==="file_upload" && <div className='w-full border-2 border-gray-200 rounded-xl shadow flex flex-col items-start p-6 mt-4'>
                <p className='text-md text-gray-600'>{modelName}</p>
                <h2 className='text-2xl font-bold text-gray-900 mb-4'>Document Upload and Setup</h2>
                
                {/** FILE UPLOAD */}
                <div className='w-full flex items-center justify-between my-2'>
                    <div className='text-left'>
                        <p className='text-lg font-semibold'>Upload CSV</p>
                        <p className='text-md text-gray-600'>Upload the CSV file you want to evaluate using</p>
                    </div>
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="file" accept=".csv" onChange={(e)=>fileUploadHandler(e)}/>
                        
                    </label>
                </div>
                
                    {/***===================================================================================================*/} 
                    {/***CONVERSATIONS HARDCODED */} 
                    {/***===================================================================================================*/} 
                    {convLevels>=1 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 1 Question</p>
                                <p className='text-md text-gray-600'>Question of the 1st level fieldname in CSV </p>
                            </div>
                            <select
                                value={question1Field}
                                onChange={(e) => {
                                    setQuestion1Field(e.target.value)
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                            {/* {!question1Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={question1Field} onChange={(e)=>setQuestion1Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setQuestion1Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {question1Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{question1Field}</p>
                                <button onClick={()=>setQuestion1Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>} */}
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 1 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 1st level fieldname in CSV </p>
                            </div>
                            <select
                                value={answer1Field}
                                onChange={(e) => {
                                    setAnswer1Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                       
                    </div>}
                    {convLevels>=2 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 2 Question</p>
                                <p className='text-md text-gray-600'>Question of the 2nd level fieldname in CSV </p>
                            </div>
                            <select
                                value={question2Field}
                                onChange={(e) => {
                                    setQuestion2Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 2 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 2nd level fieldname in CSV </p>
                            </div>
                            <select
                                value={answer2Field}
                                onChange={(e) => {
                                    setAnswer2Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        
                    </div>}
                    {convLevels>=3 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 3 Question</p>
                                <p className='text-md text-gray-600'>Question of the 3rd level fieldname in CSV </p>
                            </div>
                            <select
                                value={question3Field}
                                onChange={(e) => {
                                    setQuestion3Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 3 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 3rd level fieldname in CSV </p>
                            </div>
                            <select
                                value={answer3Field}
                                onChange={(e) => {
                                    setAnswer3Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        
                    </div>}
                    {convLevels>=4 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 4 Question</p>
                                <p className='text-md text-gray-600'>Question of the 4th level fieldname in CSV </p>
                            </div>
                            <select
                                value={question4Field}
                                onChange={(e) => {
                                    setQuestion4Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 4 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 4th level fieldname in CSV </p>
                            </div>
                            <select
                                value={answer4Field}
                                onChange={(e) => {
                                    setAnswer4Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        
                    </div>}
                    {convLevels>=5 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 5 Question</p>
                                <p className='text-md text-gray-600'>Question of the 5th level fieldname in CSV </p>
                            </div>
                            <select
                                value={question5Field}
                                onChange={(e) => {
                                    setQuestion5Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 5 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 5th level fieldname in CSV </p>
                            </div>
                            <select
                                value={answer5Field}
                                onChange={(e) => {
                                    setAnswer5Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        
                    </div>}
                    {convLevels>=6 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 6 Question</p>
                                <p className='text-md text-gray-600'>Question of the 6th level fieldname in CSV </p>
                            </div>
                            <select
                                value={question6Field}
                                onChange={(e) => {
                                    setQuestion6Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 6 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 6th level fieldname in CSV </p>
                            </div>
                            <select
                                value={answer6Field}
                                onChange={(e) => {
                                    setAnswer6Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        
                    </div>}
                    {convLevels>=7 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 7 Question</p>
                                <p className='text-md text-gray-600'>Question of the 7th level fieldname in CSV </p>
                            </div>
                            <select
                                value={question7Field}
                                onChange={(e) => {
                                    setQuestion7Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 7 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 7th level fieldname in CSV </p>
                            </div>
                            <select
                                value={answer7Field}
                                onChange={(e) => {
                                    setAnswer7Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        
                    </div>}
                    {convLevels>=8 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 8 Question</p>
                                <p className='text-md text-gray-600'>Question of the 8th level fieldname in CSV </p>
                            </div>
                            <select
                                value={question8Field}
                                onChange={(e) => {
                                    setQuestion8Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 8 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 8th level fieldname in CSV </p>
                            </div>
                            <select
                                value={answer8Field}
                                onChange={(e) => {
                                    setAnswer8Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        
                    </div>}
                    {convLevels>=9 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 9 Question</p>
                                <p className='text-md text-gray-600'>Question of the 9th level fieldname in CSV </p>
                            </div>
                            <select
                                value={question9Field}
                                onChange={(e) => {
                                    setQuestion9Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 9 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 9th level fieldname in CSV </p>
                            </div>
                            <select
                                value={answer9Field}
                                onChange={(e) => {
                                    setAnswer9Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        
                    </div>}
                    {convLevels>=10 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 10 Question</p>
                                <p className='text-md text-gray-600'>Question of the 10th level fieldname in CSV </p>
                            </div>
                            <select
                                value={question10Field}
                                onChange={(e) => {
                                    setQuestion10Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 10 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 10th level fieldname in CSV </p>
                            </div>
                            <select
                                value={answer10Field}
                                onChange={(e) => {
                                    setAnswer10Field(e.target.value)
                                    
                                }}
                                className="h-12 border border-gray-400 px-2"
                                >
                                <option value="">Select a field</option>
                                {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
                            </select>
                        </div>
                        
                    </div>}
                    <div className='w-full flex items-end justify-end'>
                        <button onClick={generateData} className='bg-purple-900 text-white font-semibold px-4 py-2'>{generating? "Adding...":"Add Model Data"}</button>
                    </div>
                
            </div>}
        </div>
        </motion.div>
      </div>
    );
  }