import React, { useEffect, useState } from 'react'
import {motion} from 'framer-motion'
import { BsArrowLeft } from 'react-icons/bs'
import EvaluationCard from '../Components/EvaluationCard'
import {  useNavigate, useParams } from 'react-router-dom'
import { getSession, getSessionData } from '../API/evaluation_sessions'
import { getEvaluationChat, getEvaluationQuestions } from '../API/questions'
import { getEvaluation, getEvaluationSettings } from '../API/evaluation'
import { getEvaluationData } from '../API/evaluation_data'
import { ThreeCircles } from 'react-loader-spinner'
import EvaluationCardSet from '../Components/Sessions/EvaluationCardSet'
import Confetti from 'react-confetti';
export default function Session() {
    const navigator=useNavigate()
    const params=useParams()
    const session_id=params.id
    const [loading,setLoading]=useState(true)

    const [settings,setSettings]=useState()
    const [pairedQuestions,setPairedQuestions]=useState(null)

    const [completeCount,setCompleteCount]=useState(0)
    console.log(pairedQuestions)
    const evaluationDetailsGet=async()=>{
        const session = await getSession(session_id)
        const evaluation_id=session.evaluation_id
        //getting settings 
        const evaluation_settings=await getEvaluationSettings(evaluation_id)
        setSettings(evaluation_settings)
       
        const sessionData=await getSessionData(session_id,evaluation_id)
        console.log(sessionData)
        let evaluationData=[]
        for(let i=0;i<sessionData.length;i+=1){
            const evalData=await getEvaluationData(sessionData[i].evaluation_data_id)
            const evaluationChat=await getEvaluationChat(evalData.id)
            const point={
                evalData,
                "chat":evaluationChat,
                "session_data":sessionData[i],
                "evaluation_id":evaluation_id
            }
            evaluationData.push(point)
        }
        //console.log(evaluationData)
        let uniqueFQ=[]
        
        //finding unique questions
        evaluationData.map(a=>{
            //console.log(a.evalData.first_question)
            if(!uniqueFQ.includes(a.evalData.first_question)){
                uniqueFQ.push(a.evalData.first_question)
                //pairs[a.evalData.first_question]=[a.chat]
            }else{
                const k= a.evalData.first_question
            }
        })
        //creating Array of array for each question
        let pairs={}

        uniqueFQ.map(dta=>{
            pairs[(dta)]=[]
        })
        
        evaluationData.map(a=>{
            //console.log(a.evalData.first_question)
            pairs[a.evalData.first_question].push({
                "chat":a.chat,
                "session_data":a.session_data
            })
            
        })
        //console.log(pairs)
        setPairedQuestions(pairs)
        setLoading(false)
    }
    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i],    array[j]] = [array[j], array[i]];
        }
        return array;
      }
    useEffect(()=>{
        evaluationDetailsGet()

    },[])
   
    if(loading){
        return(<div className='w-screen h-screen flex items-center justify-center'>
            <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#581c87"/> <span>Loading Evaluation Data..</span></p>
        </div>)
    }
    return (
        <div className='w-screen min-h-screen flex flex-col items-center justify-center p-8 bg-purple-100'>  
            <motion.button onClick={()=>window.history.back()} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='fixed z- top-4 left-4 p-2 text-lg border border-gray-200 bg-white rounded hover:bg-gray-100 '>
                <BsArrowLeft/>
            </motion.button>
            
            {pairedQuestions && Object.entries(pairedQuestions).map(([key,value],idx)=>{
                if(idx==completeCount){
                    return(
                        <EvaluationCardSet 
                            next={()=>setCompleteCount(completeCount+1)}
                            sessionChat={shuffleArray(value)} 
                            settings={settings} 
                    />)}
                
            })}
            {pairedQuestions && completeCount<Object.entries(pairedQuestions).length && <div className='mt-4'>
                Evaluation # <span className='px-3 py-2 text-md font-semibold border-2 border-gray-500 mr-2'>{completeCount+1}</span>/<span className='px-3 py-2 text-md font-semibold border-2 border-gray-500 ml-2'>{Object.entries(pairedQuestions).length}</span>
            </div>}
            {pairedQuestions && completeCount === Object.entries(pairedQuestions).length && <Confetti width={window.innerWidth} height={window.innerHeight} />}
            {pairedQuestions && completeCount === Object.entries(pairedQuestions).length && <div className='z-5 p-6 md:p-12 rounded shadow-md border bg-white'>
                <h2 className='text-2xl md:text-3xl text-center font-semibold text-purple-900'>🎉Congratulations!🎉</h2>
                <h5 className='text-sm md:text-lg text-center text-gray-600'>The evaluation is complete. Thank you so much for your contribution 💜</h5>
            </div>}
            
        </div>
    )
}
