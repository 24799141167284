import React from 'react'
import {motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom';
export default function ModelCard({data}) {
    const navigate=useNavigate()
    const convertDate=(dateString)=>{
        // Create a new Date object
        const date = new Date(dateString);

        // Extract the day, month, and year
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' }); // Get full month name
        const year = date.getFullYear();
        function getOrdinalSuffix(day) {
            if (day > 3 && day < 21) return 'th'; // 4th-20th always 'th'
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        }
        const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
        return formattedDate; 
    }

    return (
        <motion.div onClick={()=>navigate("/analytics/"+data.id)} whileHover={{translateY:"-5px"}} className='p-8 cursor-pointer rounded border hover:border-2 hover:border-purple-900'>
            
            <p className='text-xl font-semibold text-left text-purple-900'>{data.name}</p>
            <p className='text-sm text-left mb-4 text-gray-600'>{data.conversation_levels}</p>
            <div className='flex justify-start'>
                <h4 className='text-xs text-gray-600'><span className='px-2 py-1 rounded  bg-gray-200 text-gray-600'>created_at : {convertDate(data.created_at)}</span></h4>
            </div>
        </motion.div>
  )
}
