import {supabase} from '../supabaseClient'

export const getEvaluationQuestions=async(evaluation_id)=>{
    const model=await supabase
    .from('evaluator_evaluation_models') 
    .select()
    .eq('evaluation_id',evaluation_id)
    if(model){
        const model_id=model.data[0].id
        const eval_data=await supabase
        .from('evaluator_evaluation_data') 
        .select()
        .eq('evaluation_model_id',model_id)
        console.log(eval_data.data)
        return eval_data.data
    }
}
export const getEvaluationChat=async(evaluation_data_id)=>{
    const chat=await supabase
    .from('evaluator_evaluation_chat') 
    .select()
    .order('level', { ascending: true })
    .eq('evaluation_data_id',evaluation_data_id)
    return chat.data
    // if(model){
    //     const model_id=model.data[0].id
    //     const eval_data=await supabase
    //     .from('evaluator_evaluation_data') 
    //     .select()
    //     .eq('evaluation_model_id',model_id)
    //     console.log(eval_data.data)
    //     return eval_data.data
    // }
}
export const createModel=async(data)=>{
    const res=await supabase
    .from('evaluator_evaluation_models') 
    .insert([{
        "evaluation_id":data.evaluation_id,
        "name":data.name,
        "conversation_levels":data.conversation_levels,
     }]).select();
     
    return res.data[0]
}
