import React,{useEffect, useState} from 'react'
import { FaAngleDown, FaAngleUp, FaRegStar,FaRegStarHalfStroke ,FaStar  } from "react-icons/fa6";
import { BiUpvote,BiSolidUpvote,BiDownvote,BiSolidDownvote } from "react-icons/bi";
import { MdDone } from 'react-icons/md';
import {motion} from 'framer-motion'
import { updateEvaluationSessionData } from '../API/evaluation_sessions';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css';
import toast from 'react-hot-toast';
function ChatRow({question,answer,firstQuestion}){
    const [showAnswer,setShowAnswer]=useState(firstQuestion)
    const [showQuestion,setShowQuestion]=useState(firstQuestion)
    return(<div>
        <div className='flex flex-col w-full justify-between items-end'>
            <div className='w-4/5 bg-purple-900 text-white p-2 rounded shadow'>
                <p className='ml-4 text-xs md:text-sm text-right'>{question}</p>
            </div>
        </div>
        <div onClick={()=>setShowAnswer(!showAnswer)} className='flex w-full my-2 justify-between items-center'>
            <div className='w-4/5 bg-gray-300 p-2 rounded shadow'>
                <ReactMarkdown className="text-xs md:text-sm" children={answer} rehypePlugins={[rehypeKatex]} remarkPlugins={[remarkMath]}/>
            </div>
        </div>
      
    </div>)
}
export default function EvaluationCard({sessionDataId,addScored,selected,modelData,sessionData,settings}) {
    const [scoringStarted,setScoringStarted]=useState(false)
    
    const [overallScore,setOverallScore]=useState(0)
    const [relevanceScore,setRelvanceScore]=useState(0)
    const [correctnessScore,setCorrectnessScore]=useState(0)
    const [naturalnessScore,setNaturalnessScore]=useState(0)
    const [concisenessScore,setConcisenessScore]=useState(0)
    const [engagementScore,setEngagementScore]=useState(0)

    const [upvoted,setUpvoted]=useState(false)
    const [downvoted,setDownvoted]=useState(false)
    const [feedback,setFeedback]=useState(null)

    const [submitted,setSubmitted]=useState(false)

    const scoreSubmit=async()=>{
        const res=await updateEvaluationSessionData(sessionDataId,{
            "overall_score":overallScore,
            "relevance_score":relevanceScore,
            "correctness_score":correctnessScore,
            "naturalness_score":naturalnessScore,
            "conciseness_score":concisenessScore,
            "engagement_score":engagementScore,
            "downvote":downvoted,
            "feedback":feedback, 
            "complete":true, 
        })
        toast.success("Successfully scored a card!")
        addScored()
        setSubmitted(true)
    }

    //const settings=JSON.parse(localStorage.getItem("sal-evaluator-settings"))
    //console.log("MODEL",sessionData)
  return (
    <div className={selected ? `relative flex flex-col justify-between w-full bg-white p-4 rounded shadow-lg border-4 border-purple-900`:`relative flex flex-col justify-between w-full bg-white p-4 rounded shadow-lg `}>
        {selected && <p className='absolute z-10 -top-6 -left-6 text-3xl w-12 h-12 flex items-center justify-center font-bold rounded bg-purple-900 text-white'>*</p>}
        {modelData.map((a,idx)=>(<ChatRow question={a.question} answer={a.answer} firstQuestion={idx==0}/>)) }
        
        {!scoringStarted && <div className='w-full'>
            <motion.button whileHover={{scale:1.05}} onClick={()=>setScoringStarted(true)} className='w-full py-4 text-center mt-4 border border-purple-900 text-xl font-semibold text-purple-900'>Start Scoring</motion.button>
        </div>}
        {scoringStarted && <hr className='my-6'/>}
        {scoringStarted && <p className='font-semibold text-xl text-purple-900 text-center py-2 border rounded '>Individual Scoring</p>}
        {scoringStarted && !settings["star_score"] && <motion.div className='grid grid-cols-3 gap-2' initial={{scaleZ:0}} animate={{scaleZ:1}}>
            <div>
                <h4 className='text-xs md:text-sm font-semibold my-2 text-center'>Overall Score</h4>
                <div className='flex'>
                    <button onClick={()=>setOverallScore(overallScore-1)} className='w-12 h-12 flex items-center justify-center border border-r-0 hover:bg-gray-100 border-black'>-</button>
                    <input className='w-20 h-12 text-center' type="number" value={overallScore} onChange={(e)=>setOverallScore(e.target.value)}/>
                    <button onClick={()=>setOverallScore(overallScore+1)} className='w-12 h-12 flex items-center justify-center border border-l-0 hover:bg-gray-100 border-black'>+</button>
                </div>
            </div>
            <div>
                <h4 className='text-xs md:text-sm font-semibold my-2 text-center'>Relevance Score</h4>
                <div className='flex'>
                <button onClick={()=>setRelvanceScore(relevanceScore-1)} className='w-12 h-12 flex items-center justify-center border border-r-0 hover:bg-gray-100 border-black'>-</button>
                    <input className='w-20 h-12 text-center' type="number" value={relevanceScore} onChange={(e)=>setRelvanceScore(e.target.value)}/>
                    <button onClick={()=>setRelvanceScore(relevanceScore+1)} className='w-12 h-12 flex items-center justify-center border border-l-0 hover:bg-gray-100 border-black'>+</button>
                </div>
            </div>
            <div>
                <h4 className='text-xs md:text-sm font-semibold my-2 text-center'>Correctness Score</h4>
                <div className='flex'>
                <button onClick={()=>setCorrectnessScore(correctnessScore-1)} className='w-12 h-12 flex items-center justify-center border border-r-0 hover:bg-gray-100 border-black'>-</button>
                    <input className='w-20 h-12 text-center' type="number" value={correctnessScore} onChange={(e)=>setCorrectnessScore(e.target.value)}/>
                    <button onClick={()=>setCorrectnessScore(correctnessScore+1)} className='w-12 h-12 flex items-center justify-center border border-l-0 hover:bg-gray-100 border-black'>+</button>
                </div>
            </div>
            <div>
                <h4 className='text-xs md:text-sm font-semibold my-2 text-center'>Naturalness Score</h4>
                <div className='flex'>
                <button onClick={()=>setNaturalnessScore(naturalnessScore-1)} className='w-12 h-12 flex items-center justify-center border border-r-0 hover:bg-gray-100 border-black'>-</button>
                    <input className='w-20 h-12 text-center' type="number" value={naturalnessScore} onChange={(e)=>setNaturalnessScore(e.target.value)}/>
                    <button onClick={()=>setNaturalnessScore(naturalnessScore+1)} className='w-12 h-12 flex items-center justify-center border border-l-0 hover:bg-gray-100 border-black'>+</button>
                </div>
            </div>
            <div>
                <h4 className='text-xs md:text-sm font-semibold my-2 text-center'>Conciseness Score</h4>
                <div className='flex'>
                <button onClick={()=>setConcisenessScore(concisenessScore-1)} className='w-12 h-12 flex items-center justify-center border border-r-0 hover:bg-gray-100 border-black'>-</button>
                    <input className='w-20 h-12 text-center' type="number" value={concisenessScore} onChange={(e)=>setConcisenessScore(e.target.value)}/>
                    <button onClick={()=>setConcisenessScore(concisenessScore+1)} className='w-12 h-12 flex items-center justify-center border border-l-0 hover:bg-gray-100 border-black'>+</button>
                </div>
            </div>
            <div>
            <h4 className='text-xs md:text-sm font-semibold my-2 text-center'>Engagement Score</h4>
                <div className='flex'>
                <button onClick={()=>setEngagementScore(engagementScore-1)} className='w-12 h-12 flex items-center justify-center border border-r-0 hover:bg-gray-100 border-black'>-</button>
                    <input className='w-20 h-12 text-center' type="number" value={engagementScore} onChange={(e)=>setEngagementScore(e.target.value)}/>
                    <button onClick={()=>setEngagementScore(engagementScore+1)} className='w-12 h-12 flex items-center justify-center border border-l-0 hover:bg-gray-100 border-black'>+</button>
                </div>
            </div>
        </motion.div>}
        
        {scoringStarted && settings["star_score"] && <motion.div initial={{scaleZ:0}} animate={{scaleZ:1}}>
            <h4 className='text-xs md:text-sm font-semibold my-2 text-center'>Overall Score</h4>
            <div className='flex'>
                {[...Array(settings.score_to)].map((a,idx)=>(
                    <div className='cursor-pointer' onClick={()=>setOverallScore(idx+1)}>
                        {overallScore<=idx && <FaRegStar className='text-2xl hover:text-amber-500'/>}
                        {overallScore>idx && <FaStar className='text-2xl text-amber-500'/>}
                    </div>
                ))}
            </div>
            <h4 className='text-xs md:text-sm font-semibold my-2 text-center'>Relevance Score</h4>
            <div className='flex'>
                {[...Array(settings.score_to).keys()].map((a,idx)=>(
                    <div className='cursor-pointer' onClick={()=>setRelvanceScore(idx+1)}>
                        {relevanceScore<=idx && <FaRegStar className='text-2xl hover:text-amber-500'/>}
                        {relevanceScore>idx && <FaStar className='text-2xl text-amber-500'/>}
                    </div>
                ))}
            </div>
            <h4 className='text-xs md:text-sm font-semibold my-2 text-center'>Correctness Score</h4>
            <div className='flex'>
                {[...Array(settings.score_to).keys()].map((a,idx)=>(
                    <div className='cursor-pointer' onClick={()=>setCorrectnessScore(idx+1)}>
                        {correctnessScore<=idx && <FaRegStar className='text-2xl hover:text-amber-500'/>}
                        {correctnessScore>idx && <FaStar className='text-2xl text-amber-500'/>}
                    </div>
                ))}
            </div>
            <h4 className='text-xs md:text-sm font-semibold my-2 text-center'>Naturalness Score</h4>
            <div className='flex'>
                {[...Array(settings.score_to).keys()].map((a,idx)=>(
                    <div className='cursor-pointer' onClick={()=>setNaturalnessScore(idx+1)}>
                        {naturalnessScore<=idx && <FaRegStar className='text-2xl hover:text-amber-500'/>}
                        {naturalnessScore>idx && <FaStar className='text-2xl text-amber-500'/>}
                    </div>
                ))}
            </div>
            <h4 className='text-xs md:text-sm font-semibold my-2 text-center'>Conciseness Score</h4>
            <div className='flex'>
                {[...Array(settings.score_to).keys()].map((a,idx)=>(
                    <div className='cursor-pointer' onClick={()=>setConcisenessScore(idx+1)}>
                        {concisenessScore<=idx && <FaRegStar className='text-2xl hover:text-amber-500'/>}
                        {concisenessScore>idx && <FaStar className='text-2xl text-amber-500'/>}
                    </div>
                ))}
            </div>
            <h4 className='text-xs md:text-sm font-semibold my-2 text-center'>Engagement Score</h4>
            <div className='flex'>
                {[...Array(settings.score_to).keys()].map((a,idx)=>(
                    <div className='cursor-pointer' onClick={()=>setEngagementScore(idx+1)}>
                        {engagementScore<=idx && <FaRegStar className='text-2xl hover:text-amber-500'/>}
                        {engagementScore>idx && <FaStar className='text-2xl text-amber-500'/>}
                    </div>
                ))}
            </div>
            
        </motion.div>}
        {<hr className='my-6'/>}
        {scoringStarted && settings["downvote_answers"] && <div>
            <p className='font-semibold text-xl text-purple-900 text-center py-2 border rounded '>Correct/ Incorrect</p>
            <div className='flex text-2xl'>
                {!upvoted && <BiUpvote onClick={()=>{
                    if(downvoted){
                        setDownvoted(false)
                    }
                    setUpvoted(true)
                }} className='cursor-pointer hover:text-orange-500'/>}
                {upvoted && <BiSolidUpvote onClick={()=>setUpvoted(false)} className='cursor-pointer text-orange-500 hover:text-gray-400'/>}
                {!downvoted && <BiDownvote onClick={()=>{
                    if(upvoted){
                        setUpvoted(false)
                    }
                    setDownvoted(true)
                }} className='cursor-pointer hover:text-orange-500'/>}
                {downvoted && <BiSolidDownvote onClick={()=>setDownvoted(false)} className='cursor-pointer text-orange-500 hover:text-gray-400'/>}
            </div>
        </div>}
        {scoringStarted && settings["downvote_answers"] && <hr className='my-6'/>}
        {scoringStarted && settings["individual_feedback"] && <div>
            <p className='font-semibold text-xl text-purple-900 text-center py-2 mb-2 border rounded '>Feedback</p>
            <div className='w-full flex text-2xl'>
                <textarea value={feedback} onChange={(e)=>setFeedback(e.target.value)} className='w-full'/>
            </div>
        </div>}
        {!sessionData.complete && scoringStarted && !submitted && <div className='w-full'>
            <button onClick={scoreSubmit} className='w-full py-4 text-center mt-4 bg-purple-900 hover:bg-purple-600 text-xl font-semibold text-white'>Submit</button>
        </div>}
        {(sessionData.complete || (scoringStarted && submitted)) && <div className='w-full'>
            <p className='flex justify-center w-full py-4 text-center mt-4 text-xl bg-green-500 text-white'><MdDone/></p>
        </div>}
    </div>
  )
}
