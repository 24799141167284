import {supabase} from '../supabaseClient'

export const getModels=async(evaluation_id)=>{

    const res=await supabase
    .from('evaluator_evaluation_models') 
    .select()
    .eq('evaluation_id',evaluation_id)
    //console.log(res.data)
    return res.data
}
export const createModel=async(data)=>{
    const res=await supabase
    .from('evaluator_evaluation_models') 
    .insert([{
        "evaluation_id":data.evaluation_id,
        "name":data.name,
        "conversation_levels":data.conversation_levels,
     }]).select();
     
    return res.data[0]
}
