import React,{useEffect, useState} from 'react'
import {motion} from 'framer-motion'
import { useNavigate, useParams } from 'react-router-dom'
import { FaAngleLeft } from 'react-icons/fa'
import { useAuth } from '../Hooks/AuthContext'

import toast from 'react-hot-toast'
import { getSessionUser,createSessionUser } from '../API/evaluator_details'
import { ThreeCircles } from 'react-loader-spinner'
export default function EvaluatorProfiling() {
    const params=useParams()
    const navigate=useNavigate()

    const [status,setStatus]=useState("intro")
    const [loading,setLoading]=useState(false)
    const [formData,setFormData]=useState({})
    const [selectedGender,setSeletedGender]=useState(null)
    const [selectedOccupation,setSeletedOccupation]=useState(null)
    const [selectedDegree,setSeletedDegree]=useState(null)
    const [selectedLlmUsage,setSeletedLlmUsage]=useState(null)
    const genderOptions=["Male","Female","Other","Rather not say"]
    const occupationOptions=["Student","Teacher","Researcher","Professional","Other"]
    const degreeOptions=["Secondary School","Higher Secondary School","Undergraduate","Masters","PhD"]
    const llmUsageOptions=["Daily","Monthly","Yearly","Never Used"]

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    const saveSessionUser=async()=>{
        //console.log(formData)
        setLoading(true)
        const userData={
            session_id:params.id,
            institution:formData.institution,
            age:formData.age,
            gender:selectedGender,
            occupation:selectedOccupation,
            degree:selectedDegree,
            degree_type:formData.degree_type,
            llm_usage:selectedLlmUsage,
            user_type:"Evaluator"
        }
        const res=await createSessionUser(userData)
        console.log(res)
        setLoading(false)
       
        if(res==null){
            toast.error("Couldn't add session evaluator details")
            return
        }
        if(res!=null){
            toast.success("Evaluator user details updated!")
            navigate("/session/"+params.id)
        }
        
    }
    const sessionUserGet=async()=>{
        setLoading(true)
        const res= await getSessionUser(params.id)
        console.log(res)
        if(res){
            navigate("/session/"+params.id)
           
        }
        setLoading(false)
    }
    useEffect(()=>{
        sessionUserGet()
    },[])
    if(loading){
        return(<div className='w-screen h-screen flex items-center justify-center'>
            <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#581c87"/> <span>Loading Information....</span></p>
        </div>)
    }
    return (
        <div className='w-screen h-screen flex flex-col items-center justify-center'>
            
            {status === "intro" && <div className='w-full max-w-7xl p-12 flex flex-col items-center'>
                <motion.h2 initial={{opacity:0,translateY:"-10px"}} animate={{opacity:1,translateY:0}} transition={{duration:0.5}} className='text-3xl font-bold text-purple-900'>Welcome</motion.h2>
                <motion.p initial={{opacity:0,translateY:"-10px"}} animate={{opacity:1,translateY:0}} transition={{delay:0.5,duration:0.5}} className='text-lg  text-gray-700'>Thank you for agreeing to evaluate our dataset</motion.p>
                <motion.button onClick={()=>setStatus("input")} initial={{opacity:0,translateY:"-10px"}} animate={{opacity:1,translateY:0}} transition={{delay:1,duration:0.5}} className='my-6 px-6 py-2 border border-purple-900 font-bold text-xl text-purple-900 hover:text-white hover:bg-purple-900'>Get Started</motion.button>
            </div>}
            {status === "input" && <motion.div  initial={{opacity:0,translateY:"-10px"}} animate={{opacity:1,translateY:0}} transition={{delay:0,duration:0.5}} className='w-full max-w-7xl p-12 flex flex-col items-center'>
                <h2 className='text-3xl font-bold text-purple-900'>Evaluator Information✍</h2>
                <p className='text-sm font-semibold text-gray-700'>Answer the following questions before you start evaluating answers</p>
            </motion.div>}
            {status==="input" && <motion.div  initial={{opacity:0,translateY:"50px"}} animate={{opacity:1,translateY:0}} transition={{delay:0.5,duration:0.5}} className="space-y-4 max-w-7xl px-12 ">
                <div>
                    <label className="block text-lg text-gray-700 font-semibold">
                        Your Age
                    </label>
                    <input
                        type="number"
                        name="age"
                        value={formData.age}
                        onChange={handleChange}
                        placeholder="Your Age"
                        className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                    />
                
                </div>
                <div>
                    <label className="block text-lg text-gray-700 font-semibold">
                        Gender
                    </label>
                    <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                        {genderOptions.map(a=>(
                            <button onClick={()=>setSeletedGender(a)} className={`p-4 border rounded-md shadow ${selectedGender===a && "bg-purple-900 text-white"}`}>
                                <p className='text-sm font-semibold text-center'>{a}</p>
                            </button>
                        ))}
                    </div>
                </div>
                <div>
                    <label className="block text-lg text-gray-700 font-semibold">
                        Degree
                    </label>
                    <div className='grid grid-cols-2 md:grid-cols-5 gap-4'>
                        {degreeOptions.map(a=>(
                            <button onClick={()=>setSeletedDegree(a)} className={`p-4 border rounded-md shadow ${selectedDegree===a && "bg-purple-900 text-white"}`}>
                                <p className='text-sm font-semibold text-center'>{a}</p>
                            </button>
                        ))}
                    </div>
                </div>
                <div>
                    <label className="block text-lg text-gray-700 font-semibold">
                        Occupation
                    </label>
                    <div className='grid grid-cols-2 md:grid-cols-5 gap-4'>
                        {occupationOptions.map(a=>(
                            <button onClick={()=>setSeletedOccupation(a)} className={`p-4 border rounded-md shadow ${selectedOccupation===a && "bg-purple-900 text-white"}`}>
                                <p className='text-sm font-semibold text-center'>{a}</p>
                            </button>
                        ))}
                    </div>
                </div>
                
                <div>
                    <label className="block text-lg text-gray-700 font-semibold">
                        Degree Type
                    </label>
                    <input
                        type="text"
                        name="degree_type"
                        value={formData.degree_type}
                        onChange={handleChange}
                        placeholder="Degree Title (BSc, MBA, PhD etc.)"
                        className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                    />
                
                </div>
            
                
                <div>
                    <label className="block text-lg text-gray-700 font-semibold">
                        Your Institution
                    </label>
                    <input
                        type="text"
                        name="institution"
                        value={formData.institution}
                        onChange={handleChange}
                        placeholder="Your Institution"
                        className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                    />
                
                </div>
                <div>
                    <label className="block text-lg text-gray-700 font-semibold">
                        LLM Usage
                    </label>
                    <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                        {llmUsageOptions.map(a=>(
                            <button onClick={()=>setSeletedLlmUsage(a)} className={`p-4 border rounded-md shadow ${selectedLlmUsage===a && "bg-purple-900 text-white"}`}>
                                <p className='text-sm font-semibold text-center'>{a}</p>
                            </button>
                        ))}
                    </div>
                </div>
                
                <div className='pb-6'>
                    <button onClick={saveSessionUser} className='px-4 py-2 rounded border border-purple-900 text-purple-900 text-md font-semibold hover:bg-purple-900 hover:text-white'>{loading ?"Saving Profile..":"Save Profile"}</button>
                </div>
                    
            </motion.div>}
        </div>
    )
}
