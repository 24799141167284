import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './Pages/Home';

import ModelData from './Pages/ModelData';
import Session from './Pages/Session';
import Login from './Pages/Login';
import CreateAccount from './Pages/CreateAccount';
import ProtectedRoute from './ProtectedRoute'
import { AuthProvider } from './Hooks/AuthContext';
import { Toaster } from 'react-hot-toast';
import Evaluations from './Pages/Evaluations';
import ExpertProfiling from './Pages/ExpertProfiling';
import CreateEvaluation from './Pages/CreateEvaluation';
import ModelAnalytics from './Pages/ModelAnalytics';
import EvaluatorProfiling from './Pages/EvaluatorProfiling';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Toaster/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/create-account" element={<CreateAccount/>} />
          <Route path="/expert-profiling" element={<ProtectedRoute><ExpertProfiling/></ProtectedRoute>} />
          <Route path="/evaluations" element={<ProtectedRoute><Evaluations/></ProtectedRoute>} />
          <Route path="/evaluator-profiling/:id" element={<EvaluatorProfiling/>} />
          <Route path="/session/:id" element={<Session/>} />
          <Route path="/create-evaluation" element={<ProtectedRoute><CreateEvaluation/></ProtectedRoute>} />
          <Route path="/analytics/:id" element={<ProtectedRoute><ModelAnalytics/></ProtectedRoute>} />
          <Route path="/model-data" element={<ProtectedRoute><ModelData/></ProtectedRoute>} />

        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
