import {supabase} from '../supabaseClient'

export const getModelScores=async(model_id)=>{
    const res=await supabase
    .from('evaluator_session_data') 
    .select()
    .eq('model_id',model_id)

    let wins=0
    let overall_score=0
    let relevance_score=0
    let correctness_score=0
    let naturalness_score=0
    let conciseness_score=0
    let engagement_score=0
    let downvote=0

    let completed=0

    res.data.map(a=>{
        const dt=a
        if(dt.best){
            wins+=1
        }
        if(dt.overall_score!=null){
            overall_score+=dt.overall_score
        }
        if(dt.relevance_score!=null){
            relevance_score+=dt.relevance_score
        }
        if(dt.correctness_score!=null){
            correctness_score+=dt.correctness_score
        }
        if(dt.naturalness_score!=null){
            naturalness_score+=dt.naturalness_score
        }
        if(dt.conciseness_score!=null){
            conciseness_score+=dt.conciseness_score
        }
        if(dt.engagement_score!=null){
            engagement_score+=dt.engagement_score
        }
        if(dt.downvote){
            downvote+=1
        }
        if(dt.complete){
            completed+=1
        }
    })
    const avgScore={
        wins:wins,
        overall_score:overall_score,
        relevance_score:relevance_score,
        correctness_score:correctness_score,
        naturalness_score:naturalness_score,
        conciseness_score:conciseness_score,
        engagement_score:engagement_score,
        downvote:downvote,
        total:completed
    }
   return avgScore
}