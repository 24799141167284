import React,{useState} from 'react'
import {motion} from 'framer-motion'
import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { MdDone } from 'react-icons/md'
import { useAuth } from '../Hooks/AuthContext'
import { createEvaluation, createEvaluationSettings } from '../API/evaluation'

export default function CreateEvaluation() {
    const navigator=useNavigate()
    const {user} = useAuth()
    const [formData, setFormData] = useState({});
    const [stage,setStage]=useState("evaluation")

    const [generating,setGenerating]=useState(false)
    const [evaluationId,setEvaluationId]=useState(null)    

    const [comparison,setComparison]=useState(false)
    const [scoring,setScoring]=useState(false)
    const [feedback,setFeedback]=useState(false)
    const [downvote,setDownvote]=useState(false)

    const [comparisonCount,setComparisonCount]=useState(2)
    const [comparisonMultipleSelect,setComparisonMultipleSelect]=useState(false)
    const [comparisonSelectNone,setComparisonSelectNone]=useState(false)
    const [scoringFrom,setScroingFrom]=useState(0)
    const [scoringTo,setScroingTo]=useState(5)
    const [scoringAllowFloat,setScoringAllowFloat]=useState(false)
    const [scoringStar,setScoringStar]=useState(false)
    const [feedbackMarkdown,setFeedbackMarkdown]=useState(false)

    const [currentQuestionField,setCurrentQuestionField]=useState(null)
    const [currentAnswerField,setCurrentAnswerField]=useState(null)
    const [currentIdx,setCurrentIdx]=useState(0)
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
      const evaluationCreate=async()=>{
        setGenerating(true)
        const data={
          "title":formData.title,
          "subtitle":formData.subtitle,
          "topic":formData.topic,
          "description":formData.description,
          "user_id": localStorage.getItem("sal-evaluator-user")  
        }
        const res=await createEvaluation(data)
        if(res){
            setStage("evaluation_settings")
            console.log("EVALUATION ID ",res.id)
            setEvaluationId(res.id)
          //closeModal()
        }
      }
    const saveSettings=()=>{
        const settings={
            "comparison": comparison,
            "comparison_count": comparisonCount,
            "multiple_select": comparisonMultipleSelect,
            "select_none": comparisonSelectNone,
            "individual_scoring": scoring,
            "score_from": scoringFrom,
            "score_to": scoringTo,
            "score_allowfloat": scoringAllowFloat,
            "star_score": scoringStar,
            "individual_feedback": feedback,
            "allow_markdown": feedbackMarkdown,
            "downvote_answers":downvote 
        }
        const res=createEvaluationSettings(evaluationId,settings)
        console.log(res)
        //localStorage.setItem("sal-evaluator-settings",JSON.stringify(settings))
        navigator("/evaluations")
 
    }    
  return (
    <div className='w-screen h-screen overflow-y-hidden flex flex-col items-center justify-center bg-purple-100'>  
        <motion.button onClick={()=>navigator("/")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='fixed top-4 left-4 p-4 text-lg border border-gray-200 bg-white rounded hover:bg-gray-100 '>
            <BsArrowLeft/>
        </motion.button>
        <div className='flex flex-col items-start text-center w-4/5 bg-white rounded-lg h-4/5 shadow-lg p-8 overflow-y-auto'>
            <h2 className='text-3xl font-bold bg-purple-900 px-4 py-2 rounded-md text-white text-left'>Create New Evaluation</h2>
            {stage=="evaluation" && <div className='w-full border-2 border-gray-200 rounded-xl shadow flex flex-col items-start p-6 mt-4'>
                <h2 className='text-2xl font-bold text-gray-900 mb-4'>Evaluation Details</h2>
                    <div className='w-full'>
                        <label className="block text-lg text-left text-gray-700 font-semibold">
                            Evaluation Title
                        </label>
                        <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            placeholder="Evaluation Title"
                            className="w-full text-md p-3 border border-gray-300 rounded-lg"
                        />
                    </div>
                    <div className='w-full'>
                        <label className="block text-lg text-left text-gray-700 font-semibold">
                            Evaluation Subtitle
                        </label>
                        <input
                            type="text"
                            name="subtitle"
                            value={formData.subtitle}
                            onChange={handleChange}
                            placeholder="Evaluation Subtitle"
                            className="w-full text-md p-3 border border-gray-300 rounded-lg"
                        />
                        </div>
                    <div className='w-full'>
                        <label className="block text-lg text-left text-gray-700 font-semibold">
                            Evaluation Topic
                        </label>
                        <input
                            type="text"
                            name="topic"
                            value={formData.topic}
                            onChange={handleChange}
                            placeholder="Evaluation Topic"
                            className="w-full text-md p-3 border border-gray-300 rounded-lg"
                        />
                    </div>
                    
                    <div className='w-full'>
                        <label className="block text-lg text-left text-gray-700 font-semibold">
                            Evaluation Description
                        </label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            placeholder="Evaluation Description"
                            className="w-full text-md p-3 border border-gray-300 rounded-lg"
                        />
                    </div>
                    <div className='w-full flex justify-end'>      
                        <motion.button whileHover={{scale:1.1}} whileTap={{scale:0.9}} onClick={evaluationCreate} className='text-xl mt-4 text-purple-900 border-2 border-purple-900 font-semibold px-4 py-2'>Create Evaluation</motion.button>
                    </div>  
            </div>}
            {/** EVALUATION TYPES */}
            {stage==="evaluation_settings" && <div className='w-full border-2 border-gray-200 rounded-xl shadow flex flex-col items-start p-6 mt-4'>
                <h2 className='text-2xl font-bold text-gray-900'>Choose Evaluation Types</h2>
                {/**COMPARISON */}
                <div className='w-full flex items-center justify-between my-2'>
                    <div className='text-left'>
                        <p className='text-lg font-semibold'>Comparison</p>
                        <p className='text-md text-gray-600'>Compare between different answers</p>
                    </div>
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value={comparison} class="sr-only peer"/>
                        <div onClick={()=>setComparison(!comparison)} class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                </div>
                {/**COMPARISON MORE */}
                {comparison && <motion.div initial={{opacity:0,scaleY:0}} animate={{opacity:1,scaleY:1}} transition={{duration:0.2}} className='w-full bg-gray-100 flex flex-col items-center justify-between my-2 ml-4 px-6 py-4 rounded border'>
                    {/**Comparison Count */}
                    <div className='w-full flex items-center justify-between my-2'>
                        <div className='text-left'>
                            <p className='text-lg font-semibold'>Comparison Count</p>
                            <p className='text-md text-gray-600'>How many models to compare</p>
                        </div>
                        <label class="inline-flex items-center cursor-pointer">
                            <button onClick={()=>{
                                if(comparisonCount>0){
                                    setComparisonCount(comparisonCount-1)
                                }
                            }} className='h-12 w-12 bg-white flex items-center justify-center border-t border-b border-l border-gray-300 '><BiMinus/></button>
                            <p className='h-12 w-12 border border-gray-300 text-center flex items-center justify-center bg-white'>{comparisonCount}</p>
                            <button onClick={()=>setComparisonCount(comparisonCount+1)} className='h-12 w-12 bg-white flex items-center justify-center border-t border-b border-r border-gray-300 '><BiPlus/></button>
                        </label>
                    </div>
                    {/**Multpile Selection */}
                    <div className='w-full flex items-center justify-between my-2'>
                        <div className='text-left'>
                            <p className='text-lg font-semibold'>Multiple Select</p>
                            <p className='text-md text-gray-600'>Users can select multiple right options</p>
                        </div>
                        <label class="inline-flex items-center cursor-pointer">
                            <input type="checkbox" value={comparisonMultipleSelect} class="sr-only peer"/>
                            <div onClick={()=>setComparisonMultipleSelect(!comparisonMultipleSelect)} class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                    </div>
                    {/**No Selection */}
                    <div className='w-full flex items-center justify-between my-2'>
                        <div className='text-left'>
                            <p className='text-lg font-semibold'>Select None</p>
                            <p className='text-md text-gray-600'>Users can select zero right options</p>
                        </div>
                        <label class="inline-flex items-center cursor-pointer">
                            <input type="checkbox" value={comparisonSelectNone} class="sr-only peer"/>
                            <div onClick={()=>setComparisonSelectNone(!comparisonSelectNone)} class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                    </div>
                </motion.div>}
                {/**INDIVIDUAL SCORING */}
                <div className='w-full flex items-center justify-between my-2'>
                    <div className='text-left'>
                        <p className='text-lg font-semibold'>Individual Scoring</p>
                        <p className='text-md text-gray-600'>Each output can be scored by users</p>
                    </div>
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value={scoring} class="sr-only peer"/>
                        <div onClick={()=>setScoring(!scoring)} class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                </div>
                {/**SCORING MORE */}
                {scoring && <motion.div initial={{opacity:0,scaleY:0}} animate={{opacity:1,scaleY:1}} transition={{duration:0.2}}  className='w-full bg-gray-100 flex flex-col items-center justify-between my-2 ml-4 px-6 py-4 rounded border'>
                    {/**LOWER LIMIT */}
                    <div className='w-full flex items-center justify-between my-2'>
                        <div className='text-left'>
                            <p className='text-lg font-semibold'>From</p>
                            <p className='text-md text-gray-600'>Scoring starts from</p>
                        </div>
                        <label class="inline-flex items-center cursor-pointer">
                            <button onClick={()=>{
                                if(scoringFrom>0){
                                    setScroingFrom(scoringFrom-1)
                                }
                            }} className='h-12 w-12 bg-white flex items-center justify-center border-t border-b border-l border-gray-300 '><BiMinus/></button>
                            <p className='h-12 w-12 border border-gray-300 text-center flex items-center justify-center bg-white'>{scoringFrom}</p>
                            <button onClick={()=>setScroingFrom(scoringFrom+1)} className='h-12 w-12 bg-white flex items-center justify-center border-t border-b border-r border-gray-300 '><BiPlus/></button>
                        </label>
                    </div>
                    {/**Upper Limit */}
                    <div className='w-full flex items-center justify-between my-2'>
                        <div className='text-left'>
                            <p className='text-lg font-semibold'>To</p>
                            <p className='text-md text-gray-600'>Scores can be upto</p>
                        </div>
                        <label class="inline-flex items-center cursor-pointer ">
                            <button onClick={()=>{
                                if(scoringTo>0){
                                    setScroingTo(scoringTo-1)
                                }
                            }} className='h-12 w-12 bg-white flex items-center justify-center border-t border-b border-l border-gray-300'><BiMinus/></button>
                            <p className='h-12 w-12 border border-gray-300 text-center flex items-center justify-center bg-white'>{scoringTo}</p>
                            <button onClick={()=>setScroingTo(scoringTo+1)} className='h-12 w-12 bg-white flex items-center justify-center border-t border-b border-r border-gray-300'><BiPlus/></button>
                        </label>
                    </div>
                    {/**Allow Float */}
                    <div className='w-full flex items-center justify-between my-2'>
                        <div className='text-left'>
                            <p className='text-lg font-semibold'>Allow Float</p>
                            <p className='text-md text-gray-600'>Users can score in float</p>
                        </div>
                        <label class="inline-flex items-center cursor-pointer">
                            <input type="checkbox" value={scoringAllowFloat} class="sr-only peer"/>
                            <div onClick={()=>setScoringAllowFloat(!scoringAllowFloat)} class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                    </div>
                    {/**Star Based Scoring */}
                    <div className='w-full flex items-center justify-between my-2'>
                        <div className='text-left'>
                            <p className='text-lg font-semibold'>Star Score</p>
                            <p className='text-md text-gray-600'>Users will score with stars(Can't allow float here)</p>
                        </div>
                        <label class="inline-flex items-center cursor-pointer">
                            <input type="checkbox" value={scoringStar} class="sr-only peer"/>
                            <div onClick={()=>setScoringStar(!scoringStar)} class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                    </div>
                </motion.div>}
                {/**INDIVIDUAL FEEDBACK */}
                <div className='w-full flex items-center justify-between my-2'>
                    <div className='text-left'>
                        <p className='text-lg font-semibold'>Individual Feedback</p>
                        <p className='text-md text-gray-600'>Take user feedback on each output</p>
                    </div>
                    
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value={feedback} class="sr-only peer"/>
                        <div onClick={()=>setFeedback(!feedback)} class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                </div>
                {/**FEEDBACK MORE*/}
                {feedback && <motion.div initial={{opacity:0,scaleY:0}} animate={{opacity:1,scaleY:1}} transition={{duration:0.2}} className='w-full bg-gray-100 flex flex-col items-center justify-between my-2 ml-4 px-6 py-4 rounded border'>
                    {/**Markdown Feedback */}
                    <div className='w-full flex items-center justify-between my-2'>
                        <div className='text-left'>
                            <p className='text-lg font-semibold'>Allow Markdown</p>
                            <p className='text-md text-gray-600'>Users can add feedback in markdown</p>
                        </div>
                        <label class="inline-flex items-center cursor-pointer">
                            <input type="checkbox" value={feedbackMarkdown} class="sr-only peer"/>
                            <div onClick={()=>setFeedbackMarkdown(!feedbackMarkdown)} class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                    </div>
                    
                </motion.div>}
                {/** DOWNVOTE ANSWER */}
                <div className='w-full flex items-center justify-between my-2'>
                    <div className='text-left'>
                        <p className='text-lg font-semibold'>Downvote Answers</p>
                        <p className='text-md text-gray-600'>Users can downvote an answer if they want</p>
                    </div>
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value={downvote} class="sr-only peer"/>
                        <div onClick={()=>setDownvote(!downvote)} class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                </div>
                <div className='w-full flex justify-end'>      
                    <motion.button whileHover={{scale:1.1}} whileTap={{scale:0.9}} onClick={saveSettings} className='text-xl mt-4 text-purple-900 border-2 border-purple-900 font-semibold px-4 py-2'>Upload and Setup</motion.button>
                </div> 
            </div>}
             
        </div>
    </div>
  )
}
