import {supabase} from '../supabaseClient'

export const getModels=async(evaluation_id)=>{

    const res=await supabase
    .from('evaluator_evaluation_model') 
    .select()
    .eq('evaluation_id',evaluation_id)

    return res.data
}
export const getEvaluationData=async(evaluation_data_id)=>{
    //console.log(user_id)
    const res=await supabase
    .from('evaluator_evaluation_data') 
    .select()
    .eq('id',evaluation_data_id)

    return res.data[0]
}
export const getEvaluationDataByQuestion=async(firstQuestion,evaluation_id)=>{
    const res=await supabase
    .from('evaluator_evaluation_data') 
    .select()
    .eq('first_question',firstQuestion)
    .eq('evaluation_id',evaluation_id)


    return res.data
}
export const getEvaluationDataByModel=async(model_id)=>{
    //console.log(user_id)
    const res=await supabase
    .from('evaluator_evaluation_data') 
    .select()
    .eq('evaluation_model_id',model_id)
    .order("appeared_count",{ ascending: false })
    console.log(res.data)
    return res.data
}
export const createEvaluationData=async(data)=>{
    //ADD EvalID here as well
    const res=await supabase
    .from('evaluator_evaluation_data') 
    .insert([{
        "evaluation_model_id":data.evaluation_model_id,
        "first_question":data.first_question,
        "evaluation_id":data.evaluation_id,
     }]).select();
    // console.log(res.data)
    return res.data[0]
}
export const createEvaluationChat=async(data)=>{
    const res=await supabase
    .from('evaluator_evaluation_chat') 
    .insert([{
        "evaluation_data_id":data.evaluation_data_id,
        "question":data.question,
        "answer":data.answer,
        "level":data.level,
        "enable_retrieve":data.enable_retrieve,
        "media":data.media,
        "media_details":data.media_details,
     }]).select();
     //console.log(res.data)
    return res.data[0]
}
