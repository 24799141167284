import {supabase} from '../supabaseClient'

export const getUserEvaluations=async(user_id)=>{
    //console.log(user_id)
    const res=await supabase
    .from('evaluator_evaluation') 
    .select()
    .eq('user_id',user_id)

    return res.data
}

export const createEvaluation=async(data)=>{
    const res=await supabase
    .from('evaluator_evaluation') 
    .insert([{
        "user_id":data.user_id,
        "completed":false,
        "title":data.title,
        "subtitle":data.subtitle,
        "topic":data.topic,
        "description":data.description,
     }]).select();
    //console.log(res.data)
    return res.data[0]
}
export const createEvaluationSettings=async(evaluation_id,settings)=>{
    try{
        const res= await updateEvaluationSettings(evaluation_id,settings)
        return res.data[0]
    }catch(e){
        const res=await supabase
        .from('evaluator_evaluation_settings') 
        .insert([{
            "evaluation_id":evaluation_id,
            "comparison":settings.comparison,
            "comparison_count":settings.comparison_count,
            "multiple_select":settings.multiple_select,
            "select_none":settings.select_none,
            "individual_scoring":settings.individual_scoring,
            "score_from":settings.score_from,
            "score_to":settings.score_to,
            "star_score":settings.star_score,
            "individual_feedback":settings.individual_feedback,
            "allow_markdown":settings.allow_markdown,
            "downvote_answers":settings.downvote_answers
        }]).select();

        return res.data[0]
    }
    
}
export const getEvaluationSettings=async(evaluation_id)=>{
    const res=await supabase
    .from('evaluator_evaluation_settings') 
    .select()
    .eq('evaluation_id',evaluation_id)

    return res.data[0]
}
export const updateEvaluationSettings=async(evaluation_id,settings)=>{
    const res=await supabase
    .from('evaluator_evaluation_settings') 
    .update({
        "comparison":settings.comparison,
        "comparison_count":settings.comparison_count,
        "multiple_select":settings.multiple_select,
        "select_none":settings.select_none,
        "individual_scoring":settings.individual_scoring,
        "score_from":settings.score_from,
        "score_to":settings.score_to,
        "score_allowfloat":settings.score_allowfloat,
        "star_score":settings.star_score,
        "individual_feedback":settings.individual_feedback,
        "allow_markdown":settings.allow_markdown,
        "downvote_answers":settings.downvote_answers
     }).eq("evaluation_id",evaluation_id)
     .select();
    //console.log(res.data)
    return res.data
}
export const createEvaluationModels=async(evaluation_id,model)=>{
    const res=await supabase
    .from('evaluator_evaluation') 
    .insert([{
        "evaluation_id":evaluation_id,
        "name":model.name,
        "conversation_levels":model.conversation_levels  
     }]).select();
    //console.log(res.data[0])
    return res.data[0]
}