import React, { useEffect, useState } from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { ThreeCircles } from 'react-loader-spinner'
import { getModelScores } from '../API/analytics'
import { getEvaluationDataByModel } from '../API/evaluation_data'
import {motion} from 'framer-motion'
import {FaAngleLeft} from 'react-icons/fa'
import { PieChart, Pie, Cell, Tooltip, Legend,BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer} from 'recharts';

function ModelAnalyticsTable({analyticsData}) {
  return (
      <div className='w-full bg-white'>
         <table className='w-full mt-4'>
         <tr className='grid grid-cols-2 gap-4 border p-2'>
              <td className='text-md mx-2'>Wins</td>
              <td className="text-md text-right mx-2"><span className='text-lg font-bold'>{analyticsData.wins}</span> out of <span>{analyticsData.total}</span></td>
         </tr>
         <tr className='grid grid-cols-2 gap-4 border p-2'>
              <td className='text-md mx-2'>Avg Relevance Score</td>
              <td className="text-md text-right mx-2"><span className='text-lg font-bold'>{(analyticsData.relevance_score/analyticsData.total).toFixed(2)}</span> out of 5</td>
         </tr>
         <tr className='grid grid-cols-2 gap-4 border p-2'>
              <td className='text-md mx-2'>Avg Correctness Score</td>
              <td className="text-md text-right mx-2"><span className='text-lg font-bold'>{(analyticsData.correctness_score/analyticsData.total).toFixed(2)}</span> out of 5</td>
         </tr>
         <tr className='grid grid-cols-2 gap-4 border p-2'>
              <td className='text-md mx-2'>Avg Naturalness Score</td>
              <td className="text-md text-right mx-2"><span className='text-lg font-bold'>{(analyticsData.naturalness_score/analyticsData.total).toFixed(2)}</span> out of 5</td>
         </tr>
         <tr className='grid grid-cols-2 gap-4 border p-2'>
              <td className='text-md mx-2'>Avg Conciseness Score</td>
              <td className="text-md text-right mx-2"><span className='text-lg font-bold'>{(analyticsData.conciseness_score/analyticsData.total).toFixed(2)}</span> out of 5</td>
         </tr>
         <tr className='grid grid-cols-2 gap-4 border p-2'>
              <td className='text-md mx-2'>Avg Engagement Score</td>
              <td className="text-md text-right mx-2"><span className='text-lg font-bold'>{(analyticsData.engagement_score/analyticsData.total).toFixed(2)}</span> out of 5</td>
         </tr>
         <tr className='grid grid-cols-2 gap-4 border p-2'>
              <td className='text-md mx-2'>Wrong Answers</td>
              <td className="text-md text-right mx-2"><span className='text-lg font-bold'>{analyticsData.downvote}</span> out of {analyticsData.total}</td>
         </tr>
         </table>
         
      </div>
  )
}
function WinPieChart({analyticsData}) {
  const WIN_LOSS_COLORS = ['#581c87', '#e11d48'];
  return (
      <PieChart width={400} height={400}>
        <Pie
          data={[{name:"wins",value:analyticsData.wins},{name:"loss",value:analyticsData.total-analyticsData.wins}]}
          cx={200}
          cy={200}
          labelLine={false}
          outerRadius={150}
          fill="#8884d8"
          dataKey="value"
        >
          {[{name:"wins",value:analyticsData.wins},{name:"loss",value:analyticsData.total-analyticsData.wins}].map((entry, index) => (
            <Cell key={`cell-${index}`} fill={WIN_LOSS_COLORS[index % WIN_LOSS_COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
  )
}
function ScoreBarGraph({analyticsData}) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={[
          {name:"Overall",avg_score:(analyticsData.overall_score/analyticsData.total).toFixed(2)},
          {name:"Relevance",avg_score:(analyticsData.relevance_score/analyticsData.total).toFixed(2)},
          {name:"Correctness",avg_score:(analyticsData.correctness_score/analyticsData.total).toFixed(2)},
          {name:"Conciseness",avg_score:(analyticsData.conciseness_score/analyticsData.total).toFixed(2)},
          {name:"Naturalness",avg_score:(analyticsData.naturalness_score/analyticsData.total).toFixed(2)},
          {name:"Engagement",avg_score:(analyticsData.engagement_score/analyticsData.total).toFixed(2)},
        ]}
        layout="vertical"
        margin={{
          top: 15,
          right: 10,
          left: 30,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" domain={[0,5]}/>
        <YAxis dataKey="name" className='text-xs' type="category" />
        <Tooltip />
        <Legend />

        <Bar dataKey="avg_score" fill="#581c87" />
      </BarChart>
    </ResponsiveContainer>
  )
}




export default function ModelAnalytics() {
  const navigate=useNavigate()
  
  const params=useParams()
  const [loading,setLoading]=useState()
  const [activeTab,setActiveTab]=useState("dataset")
  const [analyticsData,setAnalyticsData]=useState()
  const [modelData,setModelData]=useState()

  const analyticsGet=async()=>{
      const res= await getModelScores(params.id)
      setAnalyticsData(res)
      console.log(res)

  }
  const modelDataGet=async()=>{
    setLoading(true)
    await analyticsGet()
    const res = await getEvaluationDataByModel(params.id)
    console.log(res)
    setModelData(res)
    setLoading(false)
  }
  useEffect(()=>{
    modelDataGet()
  },[])
  if(loading){
    return(<div className='w-screen h-screen flex items-center justify-center'>
        <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#581c87"/> <span>Loading Data...</span></p>
    </div>)
}
  return (
    <div className='w-screen min-h-screen flex flex-col items-center justify-center'>
        <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex items-center justify-start p-4 md:p-6 bg-white'>
            <motion.button onClick={()=>window.history.back()} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
                <FaAngleLeft/>
            </motion.button>
           <h2 className='ml-4 font-semibold text-md'>Model Analytics</h2>
            
        </div>
        <div className='mt-20 w-full max-w-7xl px-12 items-center justify-center'>
            <h2 className='text-3xl font-bold text-purple-900 text-center'>Model Details Page</h2>
            <p className='text-sm font-semibold text-gray-700 text-center' text-center>View and edit model data and analytics</p>
            <div className="w-full flex items-center justify-center p-6 ">
              {activeTab==="dataset" && <button className='px-4 py-2 font-semibold text-sm  text-white border  border-purple-900 bg-purple-900'>Dataset</button>}
              {activeTab!=="dataset" && <button onClick={()=>setActiveTab("dataset")} className='px-4 py-2 font-semibold text-sm border text-purple-900 border-purple-900'>Dataset</button>}
              {activeTab==="analytics" && <button className='px-4 py-2 font-semibold text-sm  text-white border  border-purple-900 bg-purple-900'>Analytics</button>}
              {activeTab!=="analytics" && <button onClick={()=>setActiveTab("analytics")} className='px-4 py-2 font-semibold text-sm border text-purple-900 border-purple-900'>Analytics</button>}
              {activeTab==="graph" && <button className='px-4 py-2 font-semibold text-sm  text-white border  border-purple-900 bg-purple-900'>Graphs</button>}
              {activeTab!=="graph" && <button onClick={()=>setActiveTab("graph")} className='px-4 py-2 font-semibold text-sm border text-purple-900 border-purple-900'>Graphs</button>}
            </div>
        </div>
        {activeTab === "dataset" && <div className='w-full max-w-7xl h-4/5 overflow-y-scroll flex flex-col items-center justify-center p-6 md:p-0'>
          <div className='p-6 w-full border rounded shadow-md mb-4 flex flex-col items-start'>
            <h2 className='font-bold text-lg md:text-2xl px-4 py-2 border-2 border-purple-900 rounded text-purple-900'>Dataset Details</h2>
            <div className='my-2 flex space-x-4 text-lg '>
              <h6 className='text-md'>Total Datapoints</h6>
              <h4 className='font-semibold text-md md:text-xl'>{modelData?.length}</h4>
            </div>
            {/* <div className='my-2 flex space-x-4 text-lg '>
              <h6>Total Appeared</h6>
              <h4 className='font-semibold text-xl'>{modelData?.length}</h4>
            </div> */}
          </div>
          
          <table className='border '>
            <thead>
              <tr className='border p-4 bg-purple-900 text-white'>
                <th className='text-sm md:text-lg font-normal p-2'>First Question</th>
                {/* <th className='text-sm md:text-lg font-normal'>Evaluated Count</th> */}
                <th className='text-sm md:text-lg font-normal p-2'>Appeared</th>
              </tr>
            </thead>
            <tbody> {modelData?.map(data=>(
              <tr className='border text-sm md:text-md'> 
                <td className='p-2'>{data.first_question}</td>
                {/* <td className="p-2 text-center">{data.evaluated_count}</td> */}
                <td className="p-2 text-center">{data.appeared_count}</td>
              </tr>
            ))}</tbody>
          </table>
         
        </div>}
        {activeTab === "graph" && <div className='w-full max-w-7xl px-6 md:px-12 items-center justify-center'>
          
          <div className='grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-4 p-2 md:p-8 bg-gray-100 rounded shadow-md border'>
            <div className='flex flex-col items-center justify-center'>
              <h2 className='w-full text-xl font-semibold text-center bg-purple-900 p-2 text-white'>Win Ratio </h2>
              <WinPieChart analyticsData={analyticsData}/>
            </div>
            <div className='flex flex-col items-center justify-center'>
              <h2 className='w-full text-xl font-semibold text-center bg-purple-900 p-2 text-white'>Average Scores </h2>
              <ScoreBarGraph analyticsData={analyticsData}/>
            </div>
           
          </div>
         
          
        </div>}
        {activeTab === "analytics" && <div className='w-full max-w-7xl px-12 items-center justify-center'>
          <div className='p-8 bg-gray-100 rounded shadow-md border my-4'>
            <h2 className='text-xl font-semibold text-center bg-purple-900 p-2 text-white'>Average Scores</h2>
            {<ModelAnalyticsTable analyticsData={analyticsData}/>}
          </div>
        </div>}
    </div>
  )
}
