import React,{useEffect, useState} from 'react'
import {motion} from 'framer-motion'
import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { MdDone,MdEdit } from 'react-icons/md'
import Papa from 'papaparse'
export default function ModelData() {
    const navigator=useNavigate()
    const [modelCount,setModelCount]=useState(2)
    const [evaluationData,setEvaluationData]=useState([])
    const [doneModelSetup,setDoneModelSetup]=useState(0)

    const [modelName,setModelName]=useState(null)

    //const [selectedFile,setSelectedFile]=useState("")
    const [convLevels,setConvLevels]=useState(2)
    const [question1Field,setQuestion1Field]=useState(null)
    const [question2Field,setQuestion2Field]=useState(null)
    const [question3Field,setQuestion3Field]=useState(null)
    const [question4Field,setQuestion4Field]=useState(null)
    const [question5Field,setQuestion5Field]=useState(null)
    const [question6Field,setQuestion6Field]=useState(null)
    const [question7Field,setQuestion7Field]=useState(null)
    const [question8Field,setQuestion8Field]=useState(null)
    const [question9Field,setQuestion9Field]=useState(null)
    const [question10Field,setQuestion10Field]=useState(null)
    const [answer1Field,setAnswer1Field]=useState(null)
    const [answer2Field,setAnswer2Field]=useState(null)
    const [answer3Field,setAnswer3Field]=useState(null)
    const [answer4Field,setAnswer4Field]=useState(null)
    const [answer5Field,setAnswer5Field]=useState(null)
    const [answer6Field,setAnswer6Field]=useState(null)
    const [answer7Field,setAnswer7Field]=useState(null)
    const [answer8Field,setAnswer8Field]=useState(null)
    const [answer9Field,setAnswer9Field]=useState(null)
    const [answer10Field,setAnswer10Field]=useState(null)

    const [question1Done,setQuestion1Done]=useState(false)
    const [question2Done,setQuestion2Done]=useState(false)
    const [question3Done,setQuestion3Done]=useState(false)
    const [question4Done,setQuestion4Done]=useState(false)
    const [question5Done,setQuestion5Done]=useState(false)
    const [question6Done,setQuestion6Done]=useState(false)
    const [question7Done,setQuestion7Done]=useState(false)
    const [question8Done,setQuestion8Done]=useState(false)
    const [question9Done,setQuestion9Done]=useState(false)
    const [question10Done,setQuestion10Done]=useState(false)
    const [answer1Done,setAnswer1Done]=useState(false)
    const [answer2Done,setAnswer2Done]=useState(false)
    const [answer3Done,setAnswer3Done]=useState(false)
    const [answer4Done,setAnswer4Done]=useState(false)
    const [answer5Done,setAnswer5Done]=useState(false)
    const [answer6Done,setAnswer6Done]=useState(false)
    const [answer7Done,setAnswer7Done]=useState(false)
    const [answer8Done,setAnswer8Done]=useState(false)
    const [answer9Done,setAnswer9Done]=useState(false)
    const [answer10Done,setAnswer10Done]=useState(false)
 

    const [comparisonCount,setComparisonCount]=useState(2)
    const [currentIdx,setCurrentIdx]=useState(0)

    // State to store parsed data
    const [modelData,setModelData]=useState([])
    useEffect(()=>{
        const settings=JSON.parse(localStorage.getItem("sal-evaluator-settings"))
        setModelCount(parseInt(settings["comparison_count"]))
        console.log(parseInt(settings["comparison_count"]))
    },[])
    const resetVariables=()=>{
        setModelName(null)
        //setSelectedFile("")
        setConvLevels(2)
        setQuestion1Field(null)
        setQuestion2Field(null)
        setQuestion3Field(null)
        setQuestion4Field(null)
        setQuestion5Field(null)
        setQuestion6Field(null)
        setQuestion7Field(null)
        setQuestion8Field(null)
        setQuestion9Field(null)
        setQuestion10Field(null)
        setAnswer1Field(null)
        setAnswer2Field(null)
        setAnswer3Field(null)
        setAnswer4Field(null)
        setAnswer5Field(null)
        setAnswer6Field(null)
        setAnswer7Field(null)
        setAnswer8Field(null)
        setAnswer9Field(null)
        setAnswer10Field(null)
        setQuestion1Done(null)
        setQuestion2Done(null)
        setQuestion3Done(null)
        setQuestion4Done(null)
        setQuestion5Done(null)
        setQuestion6Done(null)
        setQuestion7Done(null)
        setQuestion8Done(null)
        setQuestion9Done(null)
        setQuestion10Done(null)
        setAnswer1Done(null)
        setAnswer2Done(null)
        setAnswer3Done(null)
        setAnswer4Done(null)
        setAnswer5Done(null)
        setAnswer6Done(null)
        setAnswer7Done(null)
        setAnswer8Done(null)
        setAnswer9Done(null)
        setAnswer10Done(null)

    }
    const fileUploadHandler=(e)=>{
        //setSelectedFile(e.target.files[0])
        Papa.parse(e.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
              setModelData(results.data);
              //console.log(results.data) 
            },
          });
    }
    const generateData=async ()=>{
        await setDoneModelSetup(doneModelSetup+1)
        let finalData=[]
        modelData.forEach((a)=>{
            let obj={
                "question1":a[question1Field],
                "question2":a[question2Field],
                "question3":a[question3Field],
                "question4":a[question4Field],
                "question5":a[question5Field],
                "question6":a[question6Field],
                "question7":a[question7Field],
                "question8":a[question8Field],
                "question9":a[question9Field],
                "question10":a[question10Field],
                "answer1":a[answer1Field],
                "answer2":a[answer2Field],
                "answer3":a[answer3Field],
                "answer4":a[answer4Field],
                "answer5":a[answer5Field],
                "answer6":a[answer6Field],
                "answer7":a[answer7Field],
                "answer8":a[answer8Field],
                "answer9":a[answer9Field],
                "answer10":a[answer10Field]

            }
            finalData.push(obj)
        })
        //resetVariables()
        const arr=evaluationData
        arr.push({
            "name" : modelName,
            "data" : finalData,
            "conversation_levels":convLevels
        })
        console.log(arr)
        setEvaluationData(arr)
        console.log("Done Model ", doneModelSetup)
        
        if(doneModelSetup===(modelCount-1)){
            console.log("DONE")
            console.log("Array Length", evaluationData.length)
            localStorage.setItem("sal-evaluator-data",JSON.stringify(evaluationData))
            navigator("/evaluation")
        }
        console.log(finalData)
    }
  return (
    <div className='w-screen h-screen flex flex-col items-center justify-center bg-purple-100'>  
        <motion.button onClick={()=>navigator("/")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='fixed top-4 left-4 p-4 text-lg border border-gray-200 bg-white rounded hover:bg-gray-100 '>
            <BsArrowLeft/>
        </motion.button>
        <div className='flex flex-col items-start text-center w-4/5 bg-white rounded-lg h-4/5 shadow-lg p-8 overflow-y-auto'>
            <h2 className='text-3xl font-bold bg-purple-900 px-4 py-2 rounded-md text-white text-left'>Model Q/A</h2>
            
            {/**DOCUMENT UPLOAD */}
            <div className='w-full border-2 border-gray-200 rounded-xl shadow flex flex-col items-start p-6 mt-4'>
                <p className='text-md text-gray-600'>Model {doneModelSetup+1}</p>
                <h2 className='text-2xl font-bold text-gray-900 mb-4'>Document Upload and Setup</h2>
                
                {/** FILE UPLOAD */}
                <div className='w-full flex items-center justify-between my-2'>
                    <div className='text-left'>
                        <p className='text-lg font-semibold'>Upload CSV</p>
                        <p className='text-md text-gray-600'>Upload the CSV file you want to evaluate using</p>
                    </div>
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="file" accept=".csv" onChange={(e)=>fileUploadHandler(e)}/>
                        
                    </label>
                </div>
                {/** Conversation Levels */}
                <div className='w-full flex items-center justify-between my-2'>
                        <div className='text-left'>
                            <p className='text-lg font-semibold'>Conversation Levels</p>
                            <p className='text-md text-gray-600'>How many levels of conversation (max 10)</p>
                        </div>
                        <label class="inline-flex items-center cursor-pointer ">
                            <button onClick={()=>{
                                if(convLevels>0){
                                    setConvLevels(convLevels-1)
                                }
                            }} className='h-12 w-12 bg-white flex items-center justify-center border-t border-b border-l border-gray-300'><BiMinus/></button>
                            <p className='h-12 w-12 border border-gray-300 text-center flex items-center justify-center bg-white'>{convLevels}</p>
                            <button onClick={()=>{
                                if(convLevels<10){
                                    setConvLevels(convLevels+1)
                                }
                            }} className='h-12 w-12 bg-white flex items-center justify-center border-t border-b border-r border-gray-300'><BiPlus/></button>
                        </label>
                    </div>
                    {/***===================================================================================================*/} 
                    {/***CONVERSATIONS HARDCODED */} 
                    {/***===================================================================================================*/} 
                    {convLevels>=1 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 1 Question</p>
                                <p className='text-md text-gray-600'>Question of the 1st level</p>
                            </div>
                            {!question1Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={question1Field} onChange={(e)=>setQuestion1Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setQuestion1Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {question1Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{question1Field}</p>
                                <button onClick={()=>setQuestion1Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 1 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 1st level</p>
                            </div>
                            {!answer1Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={answer1Field} onChange={(e)=>setAnswer1Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setAnswer1Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {answer1Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{answer1Field}</p>
                                <button onClick={()=>setAnswer1Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                       
                    </div>}
                    {convLevels>=2 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 2 Question</p>
                                <p className='text-md text-gray-600'>Question of the 2nd level</p>
                            </div>
                            {!question2Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={question2Field} onChange={(e)=>setQuestion2Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setQuestion2Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {question2Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{question2Field}</p>
                                <button onClick={()=>setQuestion2Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 2 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 2nd level</p>
                            </div>
                            {!answer2Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={answer2Field} onChange={(e)=>setAnswer2Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setAnswer2Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {answer2Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{answer2Field}</p>
                                <button onClick={()=>setAnswer2Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        
                    </div>}
                    {convLevels>=3 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 3 Question</p>
                                <p className='text-md text-gray-600'>Question of the 3rd level</p>
                            </div>
                            {!question3Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={question3Field} onChange={(e)=>setQuestion3Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setQuestion3Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {question3Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{question3Field}</p>
                                <button onClick={()=>setQuestion3Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 3 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 3rd level</p>
                            </div>
                            {!answer3Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={answer3Field} onChange={(e)=>setAnswer3Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setAnswer3Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {answer3Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{answer3Field}</p>
                                <button onClick={()=>setAnswer3Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        
                    </div>}
                    {convLevels>=4 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 4 Question</p>
                                <p className='text-md text-gray-600'>Question of the 4th level</p>
                            </div>
                            {!question4Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={question4Field} onChange={(e)=>setQuestion4Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setQuestion4Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {question4Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{question4Field}</p>
                                <button onClick={()=>setQuestion4Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 4 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 4th level</p>
                            </div>
                            {!answer4Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={answer4Field} onChange={(e)=>setAnswer4Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setAnswer4Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {answer4Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{answer4Field}</p>
                                <button onClick={()=>setAnswer4Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        
                    </div>}
                    {convLevels>=5 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 5 Question</p>
                                <p className='text-md text-gray-600'>Question of the 5th level</p>
                            </div>
                            {!question5Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={question5Field} onChange={(e)=>setQuestion5Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setQuestion5Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {question5Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{question5Field}</p>
                                <button onClick={()=>setQuestion5Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 5 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 5th level</p>
                            </div>
                            {!answer5Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={answer5Field} onChange={(e)=>setAnswer5Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setAnswer5Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {answer5Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{answer5Field}</p>
                                <button onClick={()=>setAnswer5Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        
                    </div>}
                    {convLevels>=6 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 6 Question</p>
                                <p className='text-md text-gray-600'>Question of the 6th level</p>
                            </div>
                            {!question6Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={question6Field} onChange={(e)=>setQuestion6Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setQuestion6Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {question6Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{question6Field}</p>
                                <button onClick={()=>setQuestion6Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 6 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 6th level</p>
                            </div>
                            {!answer6Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={answer6Field} onChange={(e)=>setAnswer6Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setAnswer6Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {answer6Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{answer6Field}</p>
                                <button onClick={()=>setAnswer6Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        
                    </div>}
                    {convLevels>=7 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 7 Question</p>
                                <p className='text-md text-gray-600'>Question of the 7th level</p>
                            </div>
                            {!question7Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={question7Field} onChange={(e)=>setQuestion7Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setQuestion7Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {question7Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{question7Field}</p>
                                <button onClick={()=>setQuestion7Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 7 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 7th level</p>
                            </div>
                            {!answer7Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={answer7Field} onChange={(e)=>setAnswer7Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setAnswer7Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {answer7Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{answer7Field}</p>
                                <button onClick={()=>setAnswer7Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        
                    </div>}
                    {convLevels>=8 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 8 Question</p>
                                <p className='text-md text-gray-600'>Question of the 8th level</p>
                            </div>
                            {!question8Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={question8Field} onChange={(e)=>setQuestion8Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setQuestion8Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {question8Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{question8Field}</p>
                                <button onClick={()=>setQuestion8Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 8 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 8th level</p>
                            </div>
                            {!answer8Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={answer8Field} onChange={(e)=>setAnswer8Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setAnswer8Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {answer8Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{answer8Field}</p>
                                <button onClick={()=>setAnswer8Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        
                    </div>}
                    {convLevels>=9 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 9 Question</p>
                                <p className='text-md text-gray-600'>Question of the 9th level</p>
                            </div>
                            {!question9Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={question9Field} onChange={(e)=>setQuestion9Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setQuestion9Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {question9Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{question9Field}</p>
                                <button onClick={()=>setQuestion9Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 9 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 9th level</p>
                            </div>
                            {!answer9Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={answer9Field} onChange={(e)=>setAnswer9Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setAnswer9Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {answer9Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{answer9Field}</p>
                                <button onClick={()=>setAnswer9Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        
                    </div>}
                    {convLevels>=10 && <div className='w-full flex flex-col'>
                        {/** Question */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 10 Question</p>
                                <p className='text-md text-gray-600'>Question of the 10th level</p>
                            </div>
                            {!question10Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={question10Field} onChange={(e)=>setQuestion10Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setQuestion10Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {question10Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{question10Field}</p>
                                <button onClick={()=>setQuestion10Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        {/** Answer */}
                        <div className='w-full flex items-center justify-between my-2'>
                            <div className='text-left'>
                                <p className='text-lg font-semibold'>Level 10 Answer</p>
                                <p className='text-md text-gray-600'>Answer of the 10th level</p>
                            </div>
                            {!answer10Done && <label class="h-12 flex items-center">
                                <input placeholder='Enter the fieldname' value={answer10Field} onChange={(e)=>setAnswer10Field(e.target.value)} className='h-12 border border-gray-400' type="text" />
                                <button onClick={()=>setAnswer10Done(true)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdDone/></button>
                            </label>}
                            {answer10Done && <label class="h-12 flex items-center">
                                <p className='flex items-center h-12 text-md border px-4 py-2 border-gray-200'>{answer10Field}</p>
                                <button onClick={()=>setAnswer10Done(false)} className='h-full w-12 bg-purple-900 text-lg text-white flex items-center justify-center'><MdEdit/></button>
                            </label>}
                        </div>
                        
                    </div>}
                    <div className='w-full flex items-end justify-end'>
                        <button onClick={generateData} className='bg-purple-900 text-white font-semibold px-4 py-2'>Selection complete for Model {doneModelSetup+1}</button>
                    </div>
                
            </div>
            {(currentIdx===comparisonCount) && <div className='w-full mt-4 flex flex-col items-center justify-between'>
                <p className='font-semibold text-xl'>🎉Questions and answers of all your models have been selected🎉</p>
                <div>
                    
                    <motion.button whileHover={{scale:1.1}} whileTap={{scale:0.9}} onClick={generateData} className='text-xl mx-4 mt-4 bg-purple-900 text-white font-semibold px-4 py-2'>Start Evaluation</motion.button>
                </div>
            </div>
            }
        </div>
    </div>
  )
}
