import React from 'react'
import {motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import ProfileNavIcon from '../Components/ProfileNavIcon'
import { useAuth } from '../Hooks/AuthContext'

export default function Home() { 
    const navigator=useNavigate()
    const {user}=useAuth()
  return (
    <div className='w-screen h-screen flex flex-col items-center justify-center bg-purple-50'>
        <ProfileNavIcon/>
        <div className='flex flex-col items-center text-center'>
           
            <p className='text-sm md:text-xl -mb-2'>(Streamlined Automated LLM Human Evaluation)</p>
            <h2 className='text-3xl md:text-7xl font-bold text-purple-900'>S.A.L. Human Evaluation</h2>
            <p className='text-lg md:text-2xl'>A comparison and scoring based evalution system for your LLMs</p>
            <motion.button onClick={()=>{
              if(user){
                navigator("/evaluations")
              }else{
                navigator("/login")
              }
              
            }} whileHover={{scale:1.1}} whileTap={{scale:0.9}} className='text-xl mt-4 mb-12 font-semibold text-purple-900 px-4 py-2 border-4 border-purple-900  rounded'>Get Started</motion.button>
            <img className='w-1/2 mt-12' src="https://i.postimg.cc/TPvs8Y80/1x1-Images-18.png"/>
        </div>
     
        
        
        
    </div>
  )
}
