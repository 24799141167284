import React,{useEffect, useState} from 'react'
import {  FaAngleDown, FaAngleUp } from 'react-icons/fa'
import {motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { MdAdd } from 'react-icons/md'


import { ThreeCircles } from 'react-loader-spinner'
import { getEvaluationSettings } from '../../API/evaluation'
import AddModelModal from '../Models/AddModelModal'
import { getModels } from '../../API/models'
import ModelCard from '../Models/ModelCard'
import CreateSessionModal from '../Sessions/CreateSessionModal'
import { getEvaluationSessions } from '../../API/evaluation_sessions'
import SessionCard from '../Sessions/SessionCard'
import ModelAnalyticsCard from '../Analytics/ModelAnalyticsCard'


export default function EvaluationDetailsCard({data}) {
    const [loading,setLoading]=useState(false)
    const [showDetails,setShowDetails]=useState(false)
    const [models,setModels]=useState([])
    const [sessions,setSessions]=useState([])
    
    const [settings,setSettings]=useState(false)
    const [openCreateEvaluationModelModal,setOpenCreateEvaluationModelModal]=useState(false)
    const [openCreateSessionModal,setOpenCreateSessionModal]=useState(false)
    const [datasets,setDatasets]=useState([])
    const evaluationSettingsGet=async()=>{
       setLoading(true)
        const res= await getEvaluationSettings(data.id)
        //console.log(res)
        setSettings(res)
        setLoading(false)
    }
    const modelsGet=async()=>{
        setLoading(true)
        const res = await getModels(data.id)
        setModels(res)
        setLoading(false)
        //console.log(data.id)

    }
    const sessionsGet=async()=>{
        setLoading(true)
        const res = await getEvaluationSessions(data.id)
        //console.log(res)
        setSessions(res)
        setLoading(false)
        //console.log(data.id)

    }
    const closeModal=()=>{
        setOpenCreateEvaluationModelModal(false)
        setOpenCreateSessionModal(false)
        window.location.reload()
    }
    useEffect(()=>{
        modelsGet()
        sessionsGet()
        evaluationSettingsGet()
    },[])
    if(loading){
        return(<div className='w-screen h-screen flex items-center justify-center'>
            <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#581c87"/> <span>Loading Data...</span></p>
        </div>)
    }
    return (
        <div className='p-6 border rounded-md shadow my-2 flex flex-col'>
            <div className='w-full flex items-center justify-between'>
                <div className='flex flex-col items-start'>
                    <h3 className='p-1 px-2 rounded my-2 bg-amber-200 text-amber-700 text-xs font-semibold'>{data.topic}{data.subtopic&&` > ${data.subtopic}`}</h3>
                    <h6 className='text-md font-semibold'>{data.title}</h6>
                    <h6 className='text-gray-600 text-sm '>{data.subtitle}</h6>
                </div>
                <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} onClick={()=>setShowDetails(!showDetails)} className='text-2xl p-2 rounded-full border border-gray-300 hover:bg-gray-100'>
                    {showDetails ? <FaAngleUp/> : <FaAngleDown/>}
                </motion.button>
            </div>
            {openCreateEvaluationModelModal && <AddModelModal closeModal={closeModal} evaluation_id={data.id} comparison_count={data.comparion_count}/>}
            {openCreateSessionModal && <CreateSessionModal closeModal={closeModal} evaluation_id={data.id} />}
            
            {showDetails && <motion.div initial={{scaleY:0}} animate={{scaleY:1}} transition={{duration:0.3}} >
                <hr className='my-4'/>
                <h5 className='mt-4 mb-2 font-bold text-lg text-purple-900'>Evaluation Settings</h5>
                {/** SETTINGS */}
                {settings && <div className='grid grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-2'>
                    <div className='flex w-full justify-between p-2 bg-gray-200'>
                        <p className='pr-4 text-gray-600'>Comparsion</p>
                        <p className='font-semibold'>{settings.comparison?"✅":"🚫"}</p>
                    </div>
                    <div className='flex w-full justify-between p-2 bg-gray-200'>
                        <p className='pr-4 text-gray-600'>Comparsion Count</p>
                        <p className='font-semibold'>{settings.comparison_count?settings.comparison_count:"-"}</p>
                    </div>
                    <div className='flex w-full justify-between p-2 bg-gray-200'>
                        <p className='pr-4 text-gray-600'>Multiple Select</p>
                        <p className='font-semibold'>{settings.multiple_select?"✅":"🚫"}</p>
                    </div>
                    <div className='flex w-full justify-between p-2 bg-gray-200'>
                        <p className='pr-4 text-gray-600'>Select None</p>
                        <p className='font-semibold'>{settings.select_none?"✅":"🚫"}</p>
                    </div>
                    <div className='flex w-full justify-between p-2 bg-gray-200'>
                        <p className='pr-4 text-gray-600'>Individual Scoring</p>
                        <p className='font-semibold'>{settings.individual_scoring?"✅":"🚫"}</p>
                    </div>
                    <div className='flex w-full justify-between p-2 bg-gray-200'>
                        <p className='pr-4 text-gray-600'>Score From</p>
                        <p className='font-semibold'>{settings.score_from}</p>
                    </div>
                    <div className='flex w-full justify-between p-2 bg-gray-200'>
                        <p className='pr-4 text-gray-600'>Score To</p>
                        <p className='font-semibold'>{settings.score_to?settings.score_to:"-"}</p>
                    </div>
                    <div className='flex w-full justify-between p-2 bg-gray-200'>
                        <p className='pr-4 text-gray-600'>Star Score</p>
                        <p className='font-semibold'>{settings.star_score?"✅":"🚫"}</p>
                    </div>
                    <div className='flex w-full justify-between p-2 bg-gray-200'>
                        <p className='pr-4 text-gray-600'>Individual Feedback</p>
                        <p className='font-semibold'>{settings.individual_feedback?"✅":"🚫"}</p>
                    </div>
                    <div className='flex w-full justify-between p-2 bg-gray-200'>
                        <p className='pr-4 text-gray-600'>Allow Markdown</p>
                        <p className='font-semibold'>{settings.individual_feedback?"✅":"🚫"}</p>
                    </div>
                    <div className='flex w-full justify-between p-2 bg-gray-200'>
                        <p className='pr-4 text-gray-600'>Downvote Answers</p>
                        <p className='font-semibold'>{settings.downvote_answers?"✅":"🚫"}</p>
                    </div>
                </div>}
               
                {/** MODELS */}
                <hr className='my-4'/>
                <h5 className='mt-4 font-bold text-lg text-purple-900'>Models</h5>
                <h5 className='mb-4 text-sm text-gray-500'>These are the models that we would compare between</h5>
                {loading && (<div className='w-full h-full flex items-center justify-center'>
                    <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#047857"/> <span>"Loading datasets....</span></p>
                </div>)}
                {!loading && <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                    {models.map(a=>(<ModelCard data={a}/>))}
                    <motion.button onClick={()=>setOpenCreateEvaluationModelModal(true)} whileHover={{translateY:"-5px"}} className='p-4 cursor-pointer rounded border hover:border-2 hover:border-purple-900 text-gray-600  flex items-center flex-col justify-center'>
                        <MdAdd className='text-5xl p-2 rounded-full bg-gray-300'/>
                        <h2 className='font-semibold mt-2 '>Add Model</h2>
                    </motion.button>
                </div>}
                {/** SESSIONS */}
                <hr className='my-4'/>
                <h5 className='mt-4 font-bold text-lg text-purple-900'>Sessions</h5>
                <h5 className='mb-4 text-sm text-gray-500'>These are the models that we would compare between</h5>
                {loading && (<div className='w-full h-full flex items-center justify-center'>
                    <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#047857"/> <span>"Loading analytics....</span></p>
                </div>)}
                {!loading && <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                    {sessions && sessions.map(a=>(<SessionCard data={a}/>))}
                    <motion.button onClick={()=>setOpenCreateSessionModal(true)} whileHover={{translateY:"-5px"}} className='p-4 cursor-pointer rounded border hover:border-2 hover:border-purple-900 text-gray-600  flex items-center flex-col justify-center'>
                        <MdAdd className='text-5xl p-2 rounded-full bg-gray-300'/>
                        <h2 className='font-semibold mt-2 '>Create Session</h2>
                    </motion.button>
                </div>}

                
                <hr className='my-4'/>
                <h5 className='mt-4 font-bold text-lg text-purple-900'>Analytics</h5>
                <h5 className='mb-4 text-sm text-gray-500'>Basic Analytics of the models that you evaluated thus far</h5>
                {loading && (<div className='w-full h-full flex items-center justify-center'>
                    <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#047857"/> <span>"Loading analytics....</span></p>
                </div>)}
                {!loading && <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                    {models.map(a=>(<ModelAnalyticsCard model={a}/>))}
                </div>}


                {/* <hr className='my-4'/>
                <h5 className='mt-4 mb-2 font-bold text-lg text-purple-900'>Collaborators</h5>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                    
                </div> */}
            </motion.div>}
        </div>
    )
}
