import {supabase} from '../supabaseClient'

export const getSessionUser=async(session_id)=>{
    const res=await supabase
    .from('evaluator_session_user') 
    .select()
    .eq('session_id',session_id)

    return res.data[0]
}

export const createSessionUser=async(data)=>{
    const res=await supabase
    .from('evaluator_session_user') 
    .insert([{
        session_id:data.session_id,
        institution:data.institution,
        age:data.age,
        gender:data.gender,
        occupation:data.occupation,
        degree:data.degree,
        degree_type:data.degree_type,
        llm_usage:data.llm_usage,
        user_type:"Evaluator"
     }]).select();
     console.log(res.data)
    return res.data[0]
}
