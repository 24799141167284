import {supabase} from '../supabaseClient'

export const createUser=async(usr)=>{
    try{
        const res= await updateUser(usr)
        return res
    }
    catch(e){
        const res=await supabase
        .from('evaluator_user') 
        .insert([{
            "email":usr.email,
            "name":usr.name,
            "institution":usr.institution,
            "reason":usr.reason,
            "degree":usr.degree,
            "gender":usr.gender,
            "occupation":usr.occupation,
            "llm_usage":usr.llm_usage,
            //"image":usr.image,
            "user_type":usr.user_type,
            
        
        }]).select();
        console.log(res.data)
        return res.data
    }
    
}
export const updateUser=async(usr)=>{
    const res=await supabase
    .from('evaluator_user') 
    .update({
        "name":usr.name,
        "institution":usr.institution,
        "reason":usr.reason,
        "gender":usr.gender,
        "degree":usr.degree,
        "occupation":usr.occupation,
        "llm_usage":usr.llm_usage,
        "user_type":usr.user_type,
     }).eq('email',usr.email)
     .select();
    console.log(res.data)
    return res.data
}
export const getUserWithEmail=async(email)=>{
    const res=await supabase
    .from('evaluator_user') 
    .select()
    .eq('email',email)
    if(res.data){
        console.log(res.data[0])
        return res.data[0]
    }
    return null
    
}