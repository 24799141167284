
import React,{useState,useEffect} from 'react'
import {motion} from 'framer-motion'
import { FaAngleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { MdAdd } from 'react-icons/md'

import { getUserWithEmail } from '../API/users'
import { useAuth } from '../Hooks/AuthContext'
import {  ThreeCircles } from 'react-loader-spinner'
import { getUserEvaluations } from '../API/evaluation'
import CreateEvaluationModal from '../Components/Evaluations/CreateEvaluationModal'
import EvaluationDetailsCard from '../Components/Evaluations/EvaluationDetailCard'

export default function Evaluations() {
    const navigate=useNavigate()
    const user=useAuth()
    const [loading,setLoading]=useState(false)
    const [projects,setProjects]=useState([])
    const [loadingText,setLoadingText]=useState("Loading Projects..")
   
    
    
    const getProjects = async()=>{
        setLoading(true)
        const user_id = localStorage.getItem("sal-evaluator-user")
        //console.log(user_id)
        if(!user_id){
            //console.log("NO USER")
            const user_res = await getUserWithEmail(user.email)
            if(!user_res){
                navigate("/expert-profiling")
                return
            }
        }
        const res=await getUserEvaluations(user_id)
        setProjects(res)
        setLoading(false)

    }
    useEffect(()=>{
        getProjects()
    },[])
    if(loading){
        return(<div className='w-screen h-screen flex items-center justify-center'>
            <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#581c87"/> <span>{loadingText}</span></p>
        </div>)
    }
  return (
    <div className='w-screen min-h-screen flex flex-col items-center p-6'>
    <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex justify-start items-center md:p-6 p-4 bg-white'>
        <motion.button onClick={()=>navigate("/")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
            <FaAngleLeft/>
        </motion.button>
        <h2 className='text-md md:text-xl font-semibold ml-4'>My Evaluations </h2>

    </div>
    <button onClick={()=>{navigate("/create-evaluation")}} className='z-10 fixed top-3 md:top-20 right-4 flex items-center space-x-2 text-xs font-semibold px-4 py-2 bg-purple-900 rounded text-white hover:bg-indigo-600'><MdAdd className='text-xl'/>Create New</button>
    <div className='w-full flex flex-col items-center mt-16 md:mt-32'>
        <h2 className='text-left text-3xl font-bold '>Manage <span className='text-purple-900 '>Evaluations</span></h2>
        <p className='text-sm font-gray-600'>Create and manage your evaluations</p>
        <div className='w-full max-w-7xl grid grid-cols-1 gap-4 mt-6'>
            {!loading && projects.map(a=>(<EvaluationDetailsCard data={a}/>))}
            {loading && <p>Loading Projects...</p>}
        </div>
    </div>
  
</div>
  )
}