import React from 'react'
import { useAuth } from './Hooks/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function ProtectedRoute({children}) {
    const navigate=useNavigate()
    const { user } = useAuth();
    if (!user) {
        navigate("/login")
        return
    }
    return (
        <div>{children}</div>
    )
}
