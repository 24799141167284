import {supabase} from '../supabaseClient'

export const getEvaluationSessions=async(evaluation_id)=>{
    const res=await supabase
    .from('evaluator_session') 
    .select()
    .eq('evaluation_id',evaluation_id)

    return res.data
}
export const getSession=async(session_id)=>{
    const res=await supabase
    .from('evaluator_session') 
    .select()
    .eq('id',session_id)

    return res.data[0]
}
export const getSessionCompleteCount=async(session_id)=>{
    const res=await supabase
    .from('evaluator_session_data') 
    .select()
    .eq('session_id',session_id)
    .eq("complete",true)
    console.log(res.data.length)
    return res.data.length
}
export const getSessionData=async(session_id,evaluation_id)=>{
    const res=await supabase
    .from('evaluator_session_data') 
    .select()
    .eq('session_id',session_id)
    //.eq('session_id',session_id)

    return res.data
}
export const createSession=async(data)=>{
    const res=await supabase
    .from('evaluator_session') 
    .insert([{
        "evaluation_id":data.evaluation_id,
        "question_count":data.question_count,
        "title":data.title,
     }]).select();
    //console.log(res.data)
    return res.data[0]
}
export const createSessionData=async(data)=>{
    //Add to evaluation Count
    const evalData=await supabase
        .from('evaluator_evaluation_data') 
        .update({
            "appeared_count":data.previous_appeared_count+1,
        }).eq("id",data.evaluation_data_id)
        .select();
    //console.log(evalData)
    const res=await supabase
    .from('evaluator_session_data') 
    .insert([{
        "evaluation_data_id":data.evaluation_data_id,
        "session_id":data.session_id,
        "model_id":data.model_id
     }]).select();
    console.log(res.data)
    return res.data
}
export const updateEvaluationSessionData=async(evaluation_session_data_id,data)=>{
    console.log(evaluation_session_data_id)
    const res=await supabase
    .from('evaluator_session_data') 
    .update({
        "overall_score":data.overall_score,
        "relevance_score":data.relevance_score,
        "correctness_score":data.correctness_score,
        "naturalness_score":data.naturalness_score,
        "conciseness_score":data.conciseness_score,
        "engagement_score":data.engagement_score,
        "downvote":data.downvote,
        "best":data.best,
        "feedback":data.feedback,
        "complete":true, 
     }).eq("id",evaluation_session_data_id)
     .select();
    console.log(res.data)
    return res.data
}
