import React, { useState } from "react";
import {motion} from 'framer-motion'
import { MdClose } from "react-icons/md";
import { getEvaluationQuestions } from "../../API/questions";
import { createSession, createSessionData } from "../../API/evaluation_sessions";
import { getEvaluationDataByQuestion } from "../../API/evaluation_data";
function QuestionCard({data,selectedQuestions,addQuestion}){
    
    return(<div onClick={()=>addQuestion(data)} className={`w-full grid grid-cols-3 px-4 py-2 border ${!selectedQuestions.includes(data) && "cursor-pointer hover:border-purple-900"}`}> 
        <p> {selectedQuestions.includes(data) && "✅ "}{data.first_question}</p>
        <p className="text-center">{data.evaluated_count?data.evaluated_count : "-"}</p>
        <p className="text-center">{data.appeared_count?data.appeared_count : "-"}</p>
    </div>)
}

export default function CreateSessionModal({closeModal,evaluation_id }) {
    const [stage,setStage]=useState("details")
    const [formData, setFormData] = useState({});
    const [models, setModels] = useState([]);
    const [generating,setGenerating]=useState(false)
    const [questions,setQuestions]=useState(null)
    const [selectedQuestions,setSelectedQuestions]=useState([])
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
    const addQuestion=(question)=>{
        if(formData.question_count>selectedQuestions.length){
            setSelectedQuestions([...selectedQuestions,question])
        }
        
    }
    const evaluationDataByQuestion=async(ques)=>{
      const res=await getEvaluationDataByQuestion(ques)
      setModels(res)
    }
    // const sessionCreate=async()=>{
    //   setGenerating(true)
    //   const data={
    //     "evaluation_id":evaluation_id,
    //     "question_count":formData.question_count,
    //     "title":formData.title,
        
    //   }
    //   //first we will create the session
    //   const res=await createSession(data)
    //   const session_id=res.id

    //   //Now for each model, we create chats
    //   await selectedQuestions.map(async(data)=>{
    //     const questions=await getEvaluationDataByQuestion(data.first_question)
    //     //console.log("QUESTIONS : ",questions)
       
    //     const promises = Promise.all(questions.map(async(a)=>{
    //       const response=await createSessionData({
    //         "evaluation_data_id":a.id,
    //         "session_id":session_id,
    //         "previous_appeared_count":a.appeared_count,
    //         "model_id":a.evaluation_model_id
    //       })
    //       console.log('RESPONSE : ',response)
    //     }))
    //     await Promise.all(promises)
        
    //   })
    //   closeModal()
    // }
    const sessionCreate = async () => {
      setGenerating(true);
      const data = {
        evaluation_id: evaluation_id,
        question_count: formData.question_count,
        title: formData.title,
      };
    
      // First, create the session
      try {
        const res = await createSession(data);
        const session_id = res.id;
    
        // Now, for each selected question, we create chats
        for (const question of selectedQuestions) {
          const questions = await getEvaluationDataByQuestion(question.first_question,evaluation_id);
          
          for (const a of questions) {
            const response = await createSessionData({
              evaluation_data_id: a.id,
              session_id: session_id,
              previous_appeared_count: a.appeared_count,
              model_id: a.evaluation_model_id,
            });
            console.log('RESPONSE : ', response);
          }
        }
    
        closeModal();
      } catch (error) {
        console.error('Error creating session:', error);
        setGenerating(false);
      }
    };
    
   
    const evaluationQuestionsGet=async()=>{
       
        const res=await getEvaluationQuestions(evaluation_id)
        setQuestions(res)
        setStage("questions")
    }
    return (
      <div className="z-10 fixed h-full inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
        <motion.div initial={{translateY:-window.innerHeight/2}} animate={{translateY:0}} className="w-4/5 max-h-4/5 relative bg-white m-4 rounded-md shadow-lg ">
            <div className="flex flex-col  justify-center rounded-t-md bg-purple-800 py-12 text-gray-200 px-8">
                <button onClick={closeModal} className="absolute top-6 right-6 text-xl hover:text-gray-300"><MdClose className="hover:color-gray-500"/></button>
                <h2 className="text-2xl font-bold text-white">Create Evaluation Session</h2>
                <h2 className="text-sm">Fill in the details to create a new evaluation session</h2>
            </div>
            {stage=="details" && <div className="space-y-4 p-12 ">
                <div>
                  <label className="block text-sm text-gray-700 font-semibold">
                      Evaluation Session Title
                  </label>
                  <input
                      type="text"
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                      placeholder="Evaluation Title"
                      className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-700 font-semibold">
                      Question Count
                  </label>
                  <input
                      type="text"
                      name="question_count"
                      value={formData.question_count}
                      onChange={handleChange}
                      placeholder="Evaluation Subtitle"
                      className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                  />
                </div>
                <button onClick={evaluationQuestionsGet} className="text-sm font-semibold text-white hover:bg-purple-900 bg-purple-800 px-4 py-2 rounded">Next</button>
                
            </div>}
            {stage==="questions" && <div className="p-12 ">
                <h5 className="text-lg font-semibold">Click on the questions you want to be in your evaluation session</h5>
                <div className="max-h-[500px] overflow-y-scroll ">
                  {questions.map(a=>(<QuestionCard data={a} selectedQuestions={selectedQuestions} addQuestion={addQuestion}/>))}   
                </div>
                <button onClick={sessionCreate} className="text-sm mt-4 font-semibold text-white hover:bg-purple-900 bg-purple-800 px-4 py-2 rounded">{generating?"Creating...":"Create"}</button> 
            </div>}
            
        </motion.div>
      </div>
    );
  }