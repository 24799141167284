import React,{useState} from 'react'
import EvaluationCard from '../EvaluationCard'
import { updateEvaluationSessionData } from '../../API/evaluation_sessions'
import {motion} from 'framer-motion'
import toast from 'react-hot-toast'

const ProgressBar = ({ progress }) => {
    // Ensure progress is between 0 and 100
    const clampedProgress = Math.min(100, Math.max(0, progress));
  
    return (
      <div className="progress-container" style={{
        width: '100%',
        backgroundColor: '#e0e0e0',
        borderRadius: '8px',
        margin: '20px 0'
      }}>
        <div className="progress-bar" style={{
          width: `${clampedProgress}%`,
          backgroundColor: '#4CAF50',
          height: '30px',
          borderRadius: '8px',
          transition: 'width 0.5s ease-in-out',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          fontWeight: 'bold'
        }}>
          {clampedProgress}%
        </div>
      </div>
    );
  };
export default function EvaluationCardSet({sessionChat,settings,next}) {
    const [selectedAnswers,setSelectedAnswers]=useState([])
    const [bestChats,setBestChats]=useState([])
    const [scoredCount,setScoredCount]=useState(0)

    const submitBest=async()=>{
        if(!settings.select_none && bestChats.length===0){
            toast.error("Choose best chat!")
            return
        }
        bestChats.map(async(a)=>{
            const res=await updateEvaluationSessionData(a,{
                "best":true,
            })
        })
        next()
    }

    const addBestChat=(id,idx)=>{
        // multiple_selection
        if(settings.multiple_select || bestChats.length===0){
            setBestChats((bestChats)=>[...bestChats,id])
            setSelectedAnswers(selectedAnswers=>[...selectedAnswers,idx])
        }
        //zero_selection
        else{
            setBestChats([id])
            setSelectedAnswers([idx])
        }   
       
    }
    const removeBestChat=(id,idx)=>{
        setBestChats(bestChats.filter(item => item !== id))
        setSelectedAnswers(selectedAnswers.filter(item => item !== idx));
    }
    return (
        <div>
            <motion.button onClick={async()=>{
                if(scoredCount===sessionChat.length){
                    if(!bestChats){
                        toast.error("Choose the best chat")
                        return
                    }
                    await submitBest()
                    
                }else{
                    toast.error("Please do the individual scores first!")
                }
                
            }} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='fixed z-10 top-4 right-4 p-2 px-4 text-sm font-bold border border-gray-200 bg-purple-900 text-white rounded'>
                Next Question
            </motion.button>
            {/**PROGRES BAR */}
            <div className='p-2'></div>
            <ProgressBar  progress={(scoredCount/sessionChat.length)*100} />
            

            <div className='flex justify-center items-center mt-4'>
                <div className='text-right'>
                    <p className='text-xl '>Choose best option : </p>
                    {settings.multiple_select && <p className='text-sm text-gray-600'>(Select multiple if you think it's a draw)</p>}
                </div>
                {[...Array(sessionChat.length)].map((a,idx)=>(
                    <div className='ml-4'>
                        {selectedAnswers.includes(idx+1) && <p onClick={()=>{
                            removeBestChat(sessionChat[idx].session_data.id,idx+1)
                            console.log(selectedAnswers)
                            //setSelectedAnswer(idx+1)
                        }} className='text-3xl w-12 h-12 flex items-center justify-center font-bold rounded bg-purple-900 border-4 border-purple-600 text-white'>{idx+1}</p>}
                        {!(selectedAnswers.includes(idx+1)) && <p onClick={()=>{
                            addBestChat(sessionChat[idx].session_data.id,idx+1)
                            //setSelectedAnswer(idx+1)
                        }} className='text-3xl w-12 h-12 flex items-center justify-center font-bold rounded bg-white text-purple border-4 border-gray-200 hover:border-purple-900 cursor-pointer'>{idx+1}</p>}
                    </div>
                ))}
            </div>
            <div className={`grid grid-cols-1 md:grid-cols-3 p-4 gap-8`}>
                {sessionChat.map((item,idx)=>{  
                    if(!item.session_data.complete){
                        return(<EvaluationCard 
                            sessionDataId={item.session_data.id} 
                            modelData={item.chat} 
                            sessionData={item.session_data}
                            settings={settings} 
                            // addBestChat={addBestChat} 
                            // removeBestChat={removeBestChat} 
                            addScored={()=>setScoredCount(scoredCount+1)}
                            selected={selectedAnswers.includes(idx+1)} 
                        />)
                    }else{
                        next()
                    }
                    })}

            </div>
        </div>
    )
}
