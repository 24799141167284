import React, { useEffect,useState } from 'react'
import { getModelScores } from '../../API/analytics'

export default function ModelAnalyticsCard({model}) {
    //console.log(model.id)
    const [analyticsData,setAnalyticsData]=useState(null)
    const [loading,setLoading]=useState(true)
    const analyticsGet=async()=>{
        const res= await getModelScores(model.id)
        setAnalyticsData(res)
        console.log(res)
        setLoading(false)
    }
    useEffect(()=>{
        analyticsGet()
    },[])
    if(loading){
        return(<div>Loading.....</div>)
    }
    return (
        <div className='p-8 rounded border'>
           <td className='text-xl font-semibold text-left text-purple-900'>{model.name}</td>
           <table className='mt-4'>
           <tr className='grid grid-cols-2 gap-4 border'>
                <td className='text-sm mx-2'>Wins</td>
                <td className="text-sm text-right mx-2"><span className='text-lg font-bold'>{analyticsData.wins}</span> out of <span>{analyticsData.total}</span></td>
           </tr>
           <tr className='grid grid-cols-2 gap-4 border'>
                <td className='text-sm mx-2'>Avg Relevance Score</td>
                <td className="text-sm text-right mx-2"><span className='text-lg font-bold'>{(analyticsData.relevance_score/analyticsData.total).toFixed(2)}</span> out of 5</td>
           </tr>
           <tr className='grid grid-cols-2 gap-4 border'>
                <td className='text-sm mx-2'>Avg Correctness Score</td>
                <td className="text-sm text-right mx-2"><span className='text-lg font-bold'>{(analyticsData.correctness_score/analyticsData.total).toFixed(2)}</span> out of 5</td>
           </tr>
           <tr className='grid grid-cols-2 gap-4 border'>
                <td className='text-sm mx-2'>Avg Naturalness Score</td>
                <td className="text-sm text-right mx-2"><span className='text-lg font-bold'>{(analyticsData.naturalness_score/analyticsData.total).toFixed(2)}</span> out of 5</td>
           </tr>
           <tr className='grid grid-cols-2 gap-4 border'>
                <td className='text-sm mx-2'>Avg Conciseness Score</td>
                <td className="text-sm text-right mx-2"><span className='text-lg font-bold'>{(analyticsData.conciseness_score/analyticsData.total).toFixed(2)}</span> out of 5</td>
           </tr>
           <tr className='grid grid-cols-2 gap-4 border'>
                <td className='text-sm mx-2'>Avg Engagement Score</td>
                <td className="text-sm text-right mx-2"><span className='text-lg font-bold'>{(analyticsData.engagement_score/analyticsData.total).toFixed(2)}</span> out of 5</td>
           </tr>
           <tr className='grid grid-cols-2 gap-4 border'>
                <td className='text-sm mx-2'>Wrong Answers</td>
                <td className="text-sm text-right mx-2"><span className='text-lg font-bold'>{analyticsData.downvote}</span> out of {analyticsData.total}</td>
           </tr>
           </table>
           
        </div>
    )
}
