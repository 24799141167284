import React,{useState,useEffect} from 'react'
import {motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom';
import {getSessionCompleteCount } from '../../API/evaluation_sessions'
import QRCode from "react-qr-code";
export default function SessionCard({data}) {
    const [loading,setLoading]=useState(true)
    const [completeCount,setCompleteCount]=useState(null)
    const navigate=useNavigate()
    const convertDate=(dateString)=>{
        // Create a new Date object
        const date = new Date(dateString);

        // Extract the day, month, and year
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' }); // Get full month name
        const year = date.getFullYear();
        function getOrdinalSuffix(day) {
            if (day > 3 && day < 21) return 'th'; // 4th-20th always 'th'
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        }
        const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
        return formattedDate; 
    }
    const completeCountGet=async()=>{
        const res= await getSessionCompleteCount(data.id)
        console.log(res)
        setCompleteCount(res)
        setLoading(false)
    }
    useEffect(()=>{
        completeCountGet()
    },[])
    if(loading){
        return(<div>Loading....</div>)
    }
    return (
    <motion.div onClick={()=>navigate("/evaluator-profiling/"+data.id)} whileHover={{translateY:"-5px"}} className='relative p-8 cursor-pointer rounded border hover:border-2 hover:border-purple-900'>
            {completeCount>=data.question_count && <div className='flex justify-end absolute top-4 right-4'><p className='text-xs text-right px-4 py-1 border-2 rounded-lg border-green-600 text-green-600 font-semibold'>Complete</p></div>}
            {completeCount<data.question_count && <div className='flex justify-end absolute top-4 right-4'><p className='text-xs text-right px-4 py-1 border-2 rounded-lg border-red-600 text-red-600 font-semibold'>Incomplete</p></div>}
            <p className='text-xl font-semibold text-left text-purple-900'>{data.title}</p>
            <p className='text-sm text-left mb-4 text-gray-600'><span className='font-bold text-lg'>{data.question_count}</span> Questions</p>
            <div className='flex justify-start'>
                <h4 className='text-xs text-gray-600'><span className='px-2 py-1 rounded  bg-gray-200 text-gray-600'>created_at : {convertDate(data.created_at)}</span></h4>
            </div>
            <QRCode
                size={64}
                className='absolute bottom-4 right-4 bg-gray-300'
                value={"https://sal-evaluator.web.app/evaluator-profiling/"+data.id}
                viewBox={`0 0 64 64`}
            />
        </motion.div>
  )
}
